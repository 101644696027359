import { useLocation } from "react-router-dom"
import { useEffect, useState } from "react"
import JobDetail from "./Components/JobDetail"

const JobDetails = () => {
    const [data, setData] = useState(null);
    const location = useLocation();

    useEffect(() => {
        if (location && location.state && location.state.job) {
            setData(location.state.job)
        } else {
            window.history.back()
        }
    }, [location])

    return (
        <JobDetail banner={true} job={data} />
    )
}

export default JobDetails
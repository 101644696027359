import "./blog.scss"
import { useTranslation } from "react-i18next"
import Pagination from '../../components/Helpers/Pagination';
import Banner from '../../components/Partials/Banner';
import BlogItems from './Components.js/BlogItems';
import { useEffect, useState } from "react";
import { fetchData } from "../../components/Helpers/Helper";
import { BLOG } from "../../components/Helpers/ApiRoutes";

const Blog = () => {
    const { t } = useTranslation();
    const [data, setData] = useState({});
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        fetchData(`${BLOG}?page=${currentPage}`, 'GET', '', false, true, (res) => {
            if (res.status) {
                setData(res.body)
            }
        })
    }, [currentPage])


    return (
        <div className="blog-section">
            <Banner title={t("nav.blog")} />
            <section className="blog-section blog-style-two pt-100 pb-70">
                <div className="container">
                    <div className="section-title text-center">
                        <h2>{t("news_tips_articles")}</h2>
                        <p>{t("blog_new_latest_article_short_description")}</p>
                    </div>
                    <div className="row">
                        {data.data && data.data.length > 0 && data.data.map((item, key) => <BlogItems key={key} data={item} />)}
                    </div>
                    <div className="col-md-12">
                        <Pagination paginate_data={data} onPageChange={(page) => setCurrentPage(page.selected >= 0 ? page.selected + 1 : 0)} />
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Blog
import "./faq.scss"
import Pagination from '../../components/Helpers/Pagination';
import Banner from '../../components/Partials/Banner';
import useFaqFunction from "./Components/hooks";

const Faq = () => {

 const { accordian, data, t, setCurrentPage } = useFaqFunction()


 return (
  <div className="blog-section">
   <Banner title={t("Faq")} />
   <section className="blog-section blog-style-two pt-100 pb-70">
    <div className="container">
     <div className="faq-title text-center">
      <div className="col-sm-12">
       <div className="row">
        <div className="col-sm-6">
         <div className="faq1">
          <div class=" text-center"><h2 class="m-0 text-center">Candidate</h2></div>
          <div className="accordion accordion-flush mt-5 py-2" id="accordionFlushExample">
           {accordian.map((item, index) => (
            <div className="accordion-item" key={index}>
             <h2 className="accordion-header w-100" id="flush-headingOne">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapseOne" + index} aria-expanded="false" aria-controls={"flush-collapseOne" + index}>
               {item.title}
              </button>
             </h2>
             <div id={"flush-collapseOne" + index} className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">{item.text}</div>
             </div>
            </div>
           ))}
          </div>
         </div>
        </div>
        <div className="col-sm-6">
         <div className="faq1">
          <div class=" text-center"><h2 class="m-0 text-center">Business</h2></div>
          <div className="accordion accordion-flush mt-5 py-2" id="accordionFlushExampleNew">
           {accordian.map((itemNew, index) => (
            <div className="accordion-item" key={index}>
             <h2 className="accordion-header w-100" id="flush-headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapseTwo" + index} aria-expanded="false" aria-controls={"flush-collapseTwo" + index}>
               {itemNew.title}
              </button>
             </h2>
             <div id={"flush-collapseTwo" + index} className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExampleNew">
              <div className="accordion-body">{itemNew.text}</div>
             </div>
            </div>
           ))}
          </div>
         </div>
        </div>
       </div>
      </div>
      <div className="col-md-12">
       <Pagination paginate_data={data} onPageChange={(page) => setCurrentPage(page.selected >= 0 ? page.selected + 1 : 0)} />
      </div>
     </div>
    </div>
   </section>
  </div>

 )
}

export default Faq;

import { useTranslation } from "react-i18next"
import { InputField, SubmitButton } from "../../../components/Helpers/FormHelper";
import { fetchData, initialFormState, validateForm } from "../../../components/Helpers/Helper";
import { UPDATE_PROFILE } from "../../../components/Helpers/ApiRoutes";
import { useContext, useState } from "react";
import Context from "../../../components/Helpers/Context";
import MediaUpload from "./MediaUpload";

const Basic = (props) => {
    const { t } = useTranslation();
    const [context, setContext] = useContext(Context);
    const [tinyloader, setTinyloader] = useState(false);

    const submitForm = (e) => {
        if (validateForm(e, "basic-form")) {
            setTinyloader(true)
            let formdata = new FormData(document.getElementById('basic-form'))
            fetchData(UPDATE_PROFILE, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.status) {
                    setContext(prevState => ({ ...prevState, auth: res.body }))
                    initialFormState('basic-form', false)
                }
            })
        }
    }

    return (
        <>
            <MediaUpload />
            {/* <div className="container">
            <div className="row">
                
            <div className="form-section col-sm-12">
                <form id="basic-form" action="#" method="post" className="row g-3 needs-validation" noValidate>
                    <InputField col={1} name="name" label={t("form.name")} defaultValue={context && context.auth && context.auth.name} required />
                    <InputField col={1} name="email" label={t("form.email")} defaultValue={context && context.auth && context.auth.email} readOnly required />
                    <SubmitButton noOffset={1} load={tinyloader} title={t("form.update")} action={submitForm} />
                </form>
            </div>
            </div>
            </div> */}
        </>
    )
}

export default Basic
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next"
import { noImageHandle } from "../Helpers/Helper";
import ChangeLanguage from "../Lang/ChangeLanguage";
import { useContext } from "react";
import Context from "../Helpers/Context";
import { isCompany } from "../Helpers/RoleHelper";

const FrontendHeader = () => {
    const { t } = useTranslation();
    const [context] = useContext(Context)

    return (
        <>
            <nav className="navbar navbar-expand-lg bg-primary py-3">
                <div className="container px-3">
                    <NavLink className="navbar-brand" to="/home">
                        <img src="/img/png--.png" alt="logo" height="45" onError={noImageHandle} />
                    </NavLink>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse " id="navbarNavDropdown">
                        <ul className="navbar-nav m-auto">
                            <li className="nav-item me-3">
                                <NavLink className="nav-link active" to="/home">{t("nav.home")}</NavLink>
                            </li>
                            <li className="nav-item me-3">
                                <NavLink className="nav-link" to="/about-us">{t("nav.about")}</NavLink>
                            </li>
                            <li className="nav-item me-3">
                                <NavLink className="nav-link" to="/Jobs">{t("nav.jobs")}</NavLink>
                            </li>

                            <li className="nav-item me-3">
                                <NavLink className="nav-link" to="/blog">{t("nav.blog")}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link me-3" to="/contact-us">{t("nav.contact")}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link me-3" to="/pricing">{t("nav.pricing")}</NavLink>
                            </li>
                            {isCompany && context && context.auth && <>
                            <li className="nav-item me-3">
                                <NavLink className="nav-link" to="/candidate">{t("nav.candidate")}</NavLink>
                            </li>
                            </>}
                        </ul>
                        <div className="other-option d-flex gap-3">
                            <ChangeLanguage />
                            {context && context.auth ? <NavLink className="signin-btn" to="/dashboard">{t("nav.dashboard")}</NavLink> : <NavLink className="signin-btn" to="/sign-in">{t("nav.sign_in")}</NavLink>}
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default FrontendHeader
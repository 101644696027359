import { memo, useEffect, useState } from "react";
import { chatifyfetcher, showAlert } from "../../../components/Helpers/Helper";
import { CHATIFY } from "../../../components/Helpers/ApiRoutes";
import EmojiPicker from 'emoji-picker-react';
import { trim } from "lodash";

const SendMessages = (props) => {
    const [file, setFile] = useState(null)
    const [fileType, setFileType] = useState(null)
    const [filePreview, setFilePreview] = useState(null)
    const [message, setMessage] = useState("");
    const [user, setUser] = useState(null);
    const [tinyloader, setTinyloader] = useState(false);

    useEffect(() => {
        if (props.user && props.user.fetch) {
            setUser(props.user.fetch);
        }
    }, [props.user])

    useEffect(() => {
        if (user) {
            setMessage("");
            resetFormData();
        }
    }, [user])

    const typing = (e, type = 'text') => {
        if ((type === 'text' && !file && !e.target.value) || (type === 'file' && !message && !e.target.files.length > 0) || (type === 'removeFile' && !message)) {
            props.clientSendChannel.trigger("client-typing", { from_id: props.sender.id, to_id: user.id, typing: false });
        }
        if (!file && !message && ((type === 'text' && e.target.value.length === 1) || (type === 'emoji' && e.emoji) || (type === 'file' && e.target.files.length > 0))) {
            props.clientSendChannel.trigger("client-typing", { from_id: props.sender.id, to_id: user.id, typing: true });
        }
        if (type === 'text') setMessage(e.target.value)
        if (type === 'emoji') setMessage(prev => prev + e.emoji)
        if (type === 'file') fileView(e)
        if (type === 'removeFile') resetFormData()
    }

    const fileView = (e) => {
        setFile(e.target.files.length > 0 ? e.target.files[0] : null)
        if (e.target.files && e.target.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                setFilePreview(e.target.result);
                let ext = e.target.result.split(';');
                if (ext && ext[0]) {
                    ext = ext[0].split('/');
                    if (ext && ext[1]) setFileType(ext[1]);
                }
            };
            reader.readAsDataURL(e.target.files[0]);
        } else {
            setFilePreview("");
        }
    }

    const sendMessages = () => {
        if (trim(message) || file) {
            let formdata = new FormData();
            formdata.append('id', props.user.fetch.id)
            formdata.append('temporaryMsgId', Date.now())
            formdata.append('message', message)
            formdata.append('file', file)

            props.clientSendChannel.trigger("client-typing", { from_id: props.sender.id, to_id: user.id, typing: false });
            setTinyloader(true);
            chatifyfetcher(CHATIFY.sendMessage, 'POST', formdata, true, true, (res) => {
                setTinyloader(false);
                if (res.status === "200") {
                    showAlert({
                        message: res.error.message,
                        status: false,
                    });
                    setMessage("");
                    resetFormData();
                    props.setMessageList(prev => ({ ...prev, messages: prev.messages + res.message }));
                }
            })
        }
    }

    const resetFormData = () => {
        setFile(null);
        setFileType("")
        setFilePreview("");
        if (document.getElementById("upload-file")) document.getElementById("upload-file").value = null;
    }

    const checkSubmit = (e) => {
        if (e.key === "Enter") {
            sendMessages()
        }
    }

    return (
        <>
            <div className="chat-message clearfix">
                {props.user && <>
                    <form id="send-message" method="post">
                        {filePreview && <>
                            <div className="bg-white position-relative border d-inline-flex">
                                {['jpeg', 'jpg', 'png'].includes(fileType) && <img className="p-2" height={100} src={filePreview} alt="attachment" />}
                                {['ogx', 'oga', 'ogv', 'mp4', 'mov', 'ogg', 'webm'].includes(fileType) && <video className="p-2" height={100} src={filePreview} controls />}
                                {['pdf'].includes(fileType) && <img className="p-2" height={100} src="/images/pdf.png" alt="attachment" />}
                                {!['pdf', 'jpeg', 'jpg', 'png', 'ogx', 'oga', 'ogv', 'mp4', 'mov', 'ogg', 'webm'].includes(fileType) && <img className="p-2" height={100} src="/images/google-docs.png" alt="attachment" />}

                                <button className="position-absolute end-0 text-white bg-danger" type="button" title="Remove" onClick={(e) => typing(e, 'removeFile')}>&times;</button>
                            </div>
                        </>}
                        <div className="input-group mb-0">
                            <div className="input-group-prepend ">
                                <span className="input-group-text p-0">
                                    <label htmlFor="upload-file" className="mb-0 btn border-right rounded-0">
                                        <i className="fa fa-paperclip text-info fa-lg"></i>
                                    </label>
                                    <input id="upload-file" className="file-upload__input" type="file" name="file" onChange={(e) => typing(e, 'file')} />
                                    <div className="btn-group dropup">
                                        <button type="button" className="btn" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                            <i className="fa fa-smile text-warning fa-lg"></i>
                                        </button>
                                        <ul className="dropdown-menu">
                                            <EmojiPicker emojiStyle="facebook" onEmojiClick={(e) => typing(e, 'emoji')} />
                                        </ul>
                                    </div>
                                </span>
                            </div>
                            <input type="text" className="form-control gallery-form" placeholder="Enter text here..." name="message" value={message} onChange={(e) => typing(e, 'text')} onKeyUp={(e) => checkSubmit(e)} />
                            <span className="spend-main" role="button" onClick={sendMessages}>
                                {tinyloader ? <div className="spinner-border spinner-border-sm" role="status"><span className="visually-hidden"></span></div> : <i className="fa fa-paper-plane" aria-hidden="true"></i>}
                            </span>
                        </div>
                    </form>
                </>}
            </div>
        </>
    )
}

export default memo(SendMessages)

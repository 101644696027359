import "../job-details.scss";
import { Link, useNavigate } from "react-router-dom";
import {
  fetchData,
  noImageHandle,
  showAlert,
} from "../../../components/Helpers/Helper";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Context from "../../../components/Helpers/Context";
import { GET_JOB_DETAILS } from "../../../components/Helpers/ApiRoutes";
import { isCandidate } from "../../../components/Helpers/RoleHelper";
import Banner from "../../../components/Partials/Banner";
import { ModalSection } from "../../../components/Helpers/ModalHelper";
import { ViewButtonModal } from "../../../components/Helpers/ButtonHelper";

const JobDetail = (props) => {
  const [context] = useContext(Context);
  const { t } = useTranslation();
  const [data, setData] = useState({});
  const [applied, setApplied] = useState(false);
  const [resumes, setResumes] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (props && props.job) {
      let user_id = context && context.auth && context.auth.id;
      fetchData(
        `${GET_JOB_DETAILS}/${props.job.slug}?user_id=${user_id}`,
        "GET",
        "",
        false,
        true,
        (res) => {
          if (res.status) {
            setData(res.body.job);
            setApplied(res.body.job.applied);
            // setResumes(res.body.resumes && res.body.resumes.length > 0 ? res.body.resumes[0] : false)
          }
        }
      );
    }
  }, [props, context]);

  const applyJob = () => {
    if (context && context.auth) {
      if (isCandidate) {
        if (applied) {
          showAlert({
            message: t("you_are_already_applied_this_job"),
            status: false,
          });
        } else {
          if (resumes && resumes.id) {
            fetchData(
              `job/${data.slug}/apply`,
              "POST",
              { resume_id: resumes && resumes.id, cover_letter: "yes" },
              true,
              false,
              (res) => {
                if (res.status) {
                  setApplied(true);
                  document.querySelector("#closeApplyJob").click();
                }
              }
            );
          } else {
            showAlert({
              message: t("please_complete_your_profile_first"),
              status: false,
            });
          }
        }
      } else {
        showAlert({ message: t("please_login_as_a_candidate"), status: false });
      }
    } else {
      showAlert({
        message: t("please_login_as_a_candidate_for_apply_a_job"),
        status: false,
      });
      navigate("/sign-in");
    }
  };

  const checkSelectedResume = (id) => {
    if (!resumes.id) {
      showAlert({
        message: t("please_select_your_resume"),
        status: false,
      });
    } else {
      applyJob();
    }
  };
  const openPreviousModal = () => {
    document.getElementById("clickById").click()
  }
  return (
    <>
      <div className="job-section job-details-section">
        {props.banner ? <Banner title="Jobs Details" /> : ""}
        <section className={`job-details ${props.banner && "ptb-100"}`}>
          <div className="container">
            <div className="row">
              <div className="col-xl-8">
                <div className="row">
                  {data && data.company && (
                    <>
                      <div className="col-lg-12">
                        <div className="job-details-text">
                          <div className="job-card">
                            <div className="row align-items-center">
                              <div className="col-lg-2">
                                <div className="company-logo">
                                  <Link
                                    className="manage_icon"
                                    to="/company-details"
                                    state={{ company: data.company }}
                                  >
                                    <img
                                      src={
                                        data.company && data.company.logo_url
                                          ? data.company.logo_url
                                          : ""
                                      }
                                      alt="logo"
                                      onError={noImageHandle}
                                    />
                                  </Link>
                                </div>
                              </div>
                              <div className="col-lg-10">
                                <div className="job-info">
                                  <h3>
                                    {data.company.user &&
                                      data.company.user.name}
                                  </h3>
                                  <ul>
                                    <li className="pe-0">
                                      <i className="fa fa-location pe-0"></i>{" "}
                                      {data.address ? `${data.address}, ` : ""}
                                      {data.country}
                                    </li>
                                    <li>
                                      <i className="fa fa-filter-alt"></i>
                                      {data.role && data.role.name}
                                    </li>
                                    <li>
                                      <i className="bx bx-briefcase"></i>
                                      {data.job_type && data.job_type.name}
                                    </li>
                                  </ul>
                                  <span>
                                    <i className="bx bx-paper-plane"></i>
                                    {t("apply_before")}: {data.deadline}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="details-text">
                            <h3>{t("description")}</h3>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: data.description,
                              }}
                            ></div>
                          </div>
                          <div className="details-text">
                            <h3>{t("job_details")}</h3>
                            <div className="row">
                              <div className="col-lg-6">
                                <table className="table">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <span>{t("company")}</span>
                                      </td>
                                      <td>
                                        {data.company &&
                                          data.company.user &&
                                          data.company.user.name}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <span>{t("location")}</span>
                                      </td>
                                      <td>{data.full_address}</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <span>{t("job_type")}</span>
                                      </td>
                                      <td>
                                        {data.job_type && data.job_type.name}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <span>{t("form.email")}</span>
                                      </td>
                                      <td>
                                        <a
                                          href={`mailto:${data.company &&
                                            data.company.user &&
                                            data.company.user.email
                                            }`}
                                        >
                                          {data.company &&
                                            data.company.user &&
                                            data.company.user.email}
                                        </a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="col-lg-6">
                                <table className="table">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <span>{t("experince")}</span>
                                      </td>
                                      <td>
                                        {data.experience &&
                                          data.experience.name}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <span>{t("remote")}</span>
                                      </td>
                                      <td>{data.is_remote ? "Yes" : "No"}</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <span>{t("salary")}</span>
                                      </td>
                                      <td>
                                        ${data.min_salary}-${data.max_salary}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <span>{t("website")}</span>
                                      </td>
                                      <td>
                                        <a
                                          href={
                                            data.company && data.company.website
                                          }
                                        >
                                          {data.company && data.company.website}
                                        </a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          {isCandidate && props.banner && (
                            <>
                              <div className="theme-btn">
                                {context?.auth?.resume_files?.length ? (
                                  <button
                                    className="default-btn"
                                    data-bs-toggle="modal"
                                    data-bs-target={"#applyJob"}
                                    id="clickById"
                                    disabled={applied ? true : false}
                                  >
                                    {applied ? t("applied") : t("apply_now")}
                                  </button>
                                ) : (
                                  <button
                                    className="default-btn"
                                    onClick={() => applyJob()}
                                    disabled={applied ? true : false}
                                  >
                                    {applied ? t("applied") : t("apply_now")}
                                  </button>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="mt-3 mt-lg-0 col-lg-4">
                <div className="job-sidebar">
                  <h3>{t("posted_by")}</h3>
                  <div className="posted-by">
                    {data.company && data.company.user && (
                      <>
                        <img
                          src={data.company.user.image_url}
                          className="rounded-circle"
                          alt="client"
                          onError={noImageHandle}
                        />
                        <h4>{data.company.user.username}</h4>
                        <span>
                          {t("ceo_of")} {data.company.user.name}
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <div className="job-sidebar">
                  <h3>{t("location")}</h3>
                  <iframe
                    title="map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387190.27991517034!2d-74.25987556253516!3d40.697670063539654!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1588772651198!5m2!1sen!2sbd"
                  ></iframe>
                </div>
                {data.company &&
                  data.company.user &&
                  data.company.user.social_info &&
                  data.company.user.social_info.length > 0 && (
                    <>
                      <div className="job-sidebar social-share">
                        <h3>{t("share_in")}</h3>
                        <ul>
                          {data.company.user.social_info.map((si, key) => {
                            return (
                              <li key={key}>
                                <Link to={si.url} target="_blank">
                                  <i
                                    className={`bx bxl-${si.social_media}`}
                                  ></i>
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
        </section>
      </div>
      <ModalSection
        title="Apply Job"
        modal_id="applyJob"
        size="xl"
        close_btn_id="closeApplyJob"
        footer={
          <div className="row">
            <div className="theme-btn">
              <button className="default-btn" onClick={() => checkSelectedResume()}>
                {applied ? t("applied") : t("apply_now")}
              </button>
            </div>
          </div>
        }
      >
        <div className="row">
          <div className="main-pdf-box1">
            {context?.auth?.resume_files?.map((resume, index) => {
              return (
                <div
                  className={`mb-3 pdf-box ${resumes.id === resume.id ? "resume_selected" : ""
                    }`}
                  key={index}
                  onClick={() => {
                    setResumes(resume);
                  }}
                >
                  <div className="pdf-icon position-relative">
                    <img src="./images/pdf.png" alt="" />
                    <div className="show-button showBtnNew" data-bs-toggle="modal" data-bs-target={"#viewResume_" + index} >
                      <div className="preview-btn">
                        <i className="fa-solid fa-eye"></i>
                      </div>
                    </div>
                  </div>
                  <p className="text-center mt-1 p-0 m-0">
                    {resume.name.substring(0, 20)}
                    {resume.name.length > 20 && "..."}
                  </p>


                  {/* <ViewButtonModal  className={"eye_btn"} /> */}
                </div>
              );
            })}
          </div>
        </div>
      </ModalSection>
      <div className="mt-5">
        {context?.auth?.resume_files?.map((resume, index) => {
          return (
            <ModalSection
              modal_id={"viewResume_" + index}
              title="Resume"
              size="xl"
              key={index}
              action={() => openPreviousModal()}
            >
              <iframe
                className="priview_pdf"
                src={process.env.REACT_APP_ASSET_URL + resume.file}
                title="Resume 1"
              ></iframe>
            </ModalSection>
          );
        })}
      </div>
    </>
  );
};

export default JobDetail;

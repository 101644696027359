
import { Link } from "react-router-dom"

function BreadCrumbs(props) {

    return (
        <div className="block-header">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <h2>{props.title}</h2>
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/dashboard" onClick={(e) => e.preventDefault()}><i className="fa fa-th-large"></i></Link>
                        </li>
                        {props.children}
                    </ul>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 text-end">
                    {props.btn}
                </div>
            </div>
        </div>
    )
}

export default BreadCrumbs;
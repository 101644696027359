import "./signin.scss"
import { useState } from 'react';
import AnimateSection from './Components/AnimateSection';
import SignInForm from './Components/SignInForm';
import SignUpForm from './Components/SignUpForm';
// import SocialSignIn from './Components/SocialSignIn';


const SignIn = () => {
    const [signUpClass, setSignUpClass] = useState('');

    return (
        <div className="signin-section">
            <div className={`login-section ${signUpClass}`}>
                <div className="forms-container  animate-top">
                    <div className="signin-signup">
                        <SignInForm />
                        <SignUpForm />
                        {/* <SocialSignIn /> */}
                    </div>
                </div>
                <AnimateSection setSignIn={setSignUpClass} />
            </div>
        </div>
    )
}

export default SignIn
import { Link, useNavigate } from "react-router-dom";
import { fetchData, noImageHandle } from "../../../components/Helpers/Helper";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next"
import Context from "../../../components/Helpers/Context";
import { isCandidate } from "../../../components/Helpers/RoleHelper";

const JobItem = ({ data }) => {
    const [context] = useContext(Context);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [loader, setLoader] = useState(false)
    const [fav, setFav] = useState(data.bookmarked);

    const addToFav = (slug) => {
        if(context && context.auth){
            setLoader(true)
            fetchData(`job/${slug}/bookmark`, 'POST', '', true, false, (res) => {
                setLoader(false)
                if (res.status) setFav(!fav);
            })
        }else{
            navigate("/sign-in");
        }
       
    }

    return (
        <>
            <div className="row">

                <div className="col-lg-12">
                    <div className="job-card-two">
                        {data.featured === 1 && <>
                            <div className="card__pricing">
                                <div className="card__pricing-number">
                                    <span className="card__pricing-symbol">Featured</span>
                                </div>
                            </div>
                        </>}
                        <div className="row align-items-center">
                            <div className="col-12 col-xl-2 d-flex justify-content-center items-align-center">
                                <div className="company-logo">
                                    <Link className="manage_icon" to="/company-details" state={{ company: data.company }}>
                                        <img src={data.company && data.company.logo_url} alt="logo" onError={noImageHandle} />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-12 col-xl-8">
                                <div className="job-info">
                                    <h3>
                                        <p>{data.title}</p>
                                    </h3>
                                    <ul>
                                        <li className="me-2">
                                            <i className="bx bx-briefcase"></i> {data.role && data.role.name}
                                        </li>
                                        <li className="me-2">
                                            <i className="bx bx-briefcase"></i> ${data.min_salary}-${data.max_salary}
                                        </li>
                                        {(data.address || data.country) && <li className="me-2">
                                            <i className="bx bx-location-plus"></i> {data.address ? `${data.address}, ` : ''}{data.country}
                                        </li>}
                                        {data.category && <li className="me-2">
                                            <i className={data.category.icon}></i> {data.category.name}
                                        </li>}
                                        {data.experience && <li className="me-2">
                                            <i className="bx bx-briefcase"></i> {data.experience.name}
                                        </li>}
                                        <li className="me-2">
                                            <i className="bx bx-stopwatch"></i> {data.days_remaining}
                                        </li>
                                    </ul>
                                    <span className="white-theme"> {data.job_type && data.job_type.name}</span>
                                </div>
                            </div>
                            <div className="col-12 col-xl-2">
                                <div className="d-flex align-items-center">
                                    <div className="theme-btn text-end">
                                        <Link to="/Job-details" state={{ job: data }} className="default-btn">{t("Details")}</Link>
                                    </div>
                                    <div className="JobfavrateIcon">
                                        {isCandidate && <button className="btn border-0" disabled={loader}><i className={`fa fa-heart ${fav && 'redHeart'}`} onClick={() => addToFav(data.slug)}></i></button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default JobItem
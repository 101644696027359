import { useTranslation } from "react-i18next"
import { InputField, SubmitButton } from "../../../components/Helpers/FormHelper";
import { useContext, useEffect, useState } from "react";
import Context from "../../../components/Helpers/Context";
import { fetchData, initialFormState, intlTel_phone, validateForm, validatePhone } from "../../../components/Helpers/Helper";
import { UPDATE_CONTACT_INFO } from "../../../components/Helpers/ApiRoutes";
import AccountAccess from "./AccountAccess";

const AccountSetting = (props) => {
    const { t } = useTranslation();
    const [context, setContext] = useContext(Context);
    const [tinyloader, setTinyloader] = useState(false);
    const [intlTel, setIntlTel] = useState();
    const [intlTel2, setIntlTel2] = useState();
    const [data, setData] = useState({ phone: "", email: "", secondary_phone: "", secondary_email: "" });

    useEffect(() => {
        if (context && context.auth && context.auth.contact_info) {
            setData({
                phone: context.auth.contact_info.phone ?? "",
                email: context.auth.email ?? "",
                secondary_phone: context.auth.contact_info.secondary_phone ?? "",
                secondary_email: context.auth.contact_info.secondary_email ?? ""
            })
        }
    }, [context])

    useEffect(() => {
        setTimeout(() => {
            setIntlTel(intlTel_phone('#phone', '', 'us', true));
            setIntlTel2(intlTel_phone('#secondary_phone', '', 'us', true));
        }, 1000);
    }, [])

    const submitForm = (e) => {
        let phone = intlTel.getNumber()
        let secondary_phone = intlTel2.getNumber()
        if (validateForm(e, "account-setting-form") && (!phone || validatePhone(intlTel, '#phone')) && (!secondary_phone || validatePhone(intlTel2, '#secondary_phone'))) {
            setTinyloader(true)
            let formdata = new FormData(document.getElementById('account-setting-form'))
            formdata.delete('phone')
            formdata.delete('secondary_phone')
            formdata.append('phone', phone)
            formdata.append('secondary_phone', secondary_phone)
            fetchData(UPDATE_CONTACT_INFO, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.status) {
                    setContext(prevState => ({ ...prevState, auth: res.body }))
                    initialFormState('account-setting-form', false);
                }
            })
        }
    }

    return (
        <>
            <div className="location">
                <div className="dashboard-account-setting-item pb-0">
                    <h6>Locations</h6>
                    <div className="row">
                        <div className="col-lg-12 mb-3">
                            <div className="alert alert-danger mt-0" role="alert" id="map_wrong_key_warning">You must use an API key to authenticate each request to Google Maps Platform APIs. For additional information, please refer to http://g.co/dev/maps-no-account</div>
                            <div className="">
                                <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.5739151822277!2d80.94493781504369!3d26.853501283153097!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bfd09525f13d7%3A0xf1e17e0a46f5ecac!2sNIIT%20Lucknow%20Sapru%20Marg%20Centre!5e0!3m2!1sen!2sin!4v1688374731389!5m2!1sen!2sin" width="100%" height="350" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                        <div className="col-lg-12 border-bottom mb-2">
                            <h6 className="phone">Phone & Email</h6>
                            <form id="account-setting-form" action="#" method="post" className="row g-3 needs-validation mb-2" noValidate>
                                <InputField col={1} required name="phone" label={t("form.phone")} defaultValue={data.phone} />
                                <InputField col={1} name="secondary_phone" label={t("form.secondary_phone")} defaultValue={data.secondary_phone} />
                                {/* <InputField col={1} required name="email" label={t("form.email")} defaultValue={data.email} readOnly /> */}
                                <InputField col={1} name="secondary_email" label={t("form.secondary_email")} defaultValue={data.secondary_email} />
                                <SubmitButton noOffset={1} load={tinyloader} title={t("form.update")} action={submitForm} />
                            </form>
                        </div>
                        <AccountAccess />
                    </div>
                </div>
            </div>
        </>
    )
}

export default AccountSetting
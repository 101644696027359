import { useTranslation } from "react-i18next"
import { InputField, ReactSelect, SubmitButton } from "../../../components/Helpers/FormHelper";
import { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { fetchData, initialFormState, validateForm, noImageHandle } from "../../../components/Helpers/Helper";
import { GET_PERSONAL_INFO, UPDATE_PERSONAL_INFO, UPDATE_PROFILE_IMAGE } from "../../../components/Helpers/ApiRoutes";
import Context from "../../../components/Helpers/Context";
import { DraftEditor } from "../../../components/Helpers/EditorHelper";

const Profile = (props) => {
    const { t } = useTranslation();
    const [context, setContext] = useContext(Context);
    const [tinyloader, setTinyloader] = useState(false);
    const [data, setData] = useState({ birth_date: null, nationality: null, gender: null, maritalStatus: null, profession: null, bio: null })

    const [nationality, setNationality] = useState([]);
    const [profession, setProfession] = useState([]);
    const [gender] = useState([{ value: "male", label: "Male" }, { value: "female", label: "Female" }]);
    const [maritalStatus] = useState([{ value: "married", label: "Married" }, { value: "unmarried", label: "Unmarried" }]);

    useEffect(() => {
        document.querySelector(".upload-button").addEventListener("click", () => document.querySelector(".profile-image-upload").click());
        return () => {
            if (document.querySelector(".upload-button")) document.querySelector(".upload-button").removeEventListener("click", () => { });
        };
    }, []);
    
    useEffect(() => {
        if (context && context.auth && context.auth.candidate) {
            fetchData(GET_PERSONAL_INFO, 'GET', '', true, false, (res) => {
                if (res.status) {
                    let nationality = res.body.nationality.filter((item) => item.value === context.auth.candidate.nationality_id)
                    let profession = res.body.profession.filter((item) => item.value === context.auth.candidate.profession_id)

                    setNationality(res.body.nationality)
                    setProfession(res.body.profession)
                    setData(prevState => ({ ...prevState, nationality: nationality[0] ?? null, profession: profession[0] ?? null }))
                }
            })
            let gender_select = gender.filter((item) => item.value === context.auth.candidate.gender)
            let marital_status = maritalStatus.filter((item) => item.value === context.auth.candidate.marital_status)

            setData(prevState => ({
                ...prevState,
                bio: context.auth.candidate.bio ?? '',
                gender: gender_select[0] ?? null,
                maritalStatus: marital_status[0] ?? null,
                birth_date: context.auth.candidate.birth_date ? new Date(context.auth.candidate.birth_date) : null
            }))
        }
    }, [context, gender, maritalStatus])

    const submitForm = (e) => {
        if (validateForm(e, "personal-form")) {
            setTinyloader(true)
            let formdata = new FormData(document.getElementById('personal-form'))
            fetchData(UPDATE_PERSONAL_INFO, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.status) {
                    setContext(prevState => ({ ...prevState, auth: res.body }))
                    initialFormState('personal-form', false)
                }
            })
        }
    }
    const readURL = (input) => {
        console.log(input);
        if (input.target.files && input.target.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                document.querySelector(".profile_pic").setAttribute("src", e.target.result);
            };
            reader.readAsDataURL(input.target.files[0]);
            uploadProfileImage();
        }
    };
    const uploadProfileImage = () => {
        let formdata = new FormData(document.getElementById("my-profile-image"));
        fetchData(UPDATE_PROFILE_IMAGE, "POST", formdata, true, true, (res) => {
            if (res.status) {
                setContext((prevState) => ({ ...prevState, auth: res.body }));
                removeError("my-profile-image")
            }
        });
    };
    const removeError = (form) => {
        document.querySelectorAll(`#${form} .invalid-feedback`).forEach(e => e.remove());
    }

    return (
        <>
            <div className="form-section">
                
                <form id="personal-form" action="#" method="post" className="row g-3 needs-validation" noValidate>
                    <div className="main-profile mt-5 mb-3">
                           <div className=" col-lg-6 d-flex align-items-center">
                                    <div className="profile-image position-relative d-inline-block">
                                        <div className="circle">
                                            <img className="profile_pic" alt="" src={context && context.auth && context.auth.image ? context.auth.candidate && context.auth.candidate.photo : "assets/images/user.png"} onError={noImageHandle} />
                                        </div>
                                        <div className="p-image">
                                            <i className="fa fa-camera upload-button"></i>
                                            <form id="my-profile-image" action="#" method="post">
                                                <input className="file-upload profile-image-upload" name="avatar" onChange={readURL} type="file" accept="image/*" />
                                            </form>
                                        </div>
                                    </div>
                                <div className="col-lg-6 d-flex align-items-center">
                                <InputField col={1} name="name" label={t("form.name")} defaultValue={context && context.auth && context.auth.name} required /><br/>
                                <InputField col={1} name="email" label={t("form.email")} defaultValue={context && context.auth && context.auth.email} readOnly required />

                                </div>
                                </div>
                                </div>
                    <div className="col-md-6">
                        <label htmlFor="birth_date" className="form-label">Date Of Birth<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                        <DatePicker required autoComplete="off" name="birth_date" id="birth_date" selected={data.birth_date} className="form-control" maxDate={new Date()} dateFormat="MMM dd, yyyy" onChange={(e) => setData(prevState => ({ ...prevState, birth_date: e }))} onChangeRaw={(e) => e.preventDefault()} />
                    </div>
                    <ReactSelect name="nationality" label={t("form.nationality")} required value={data.nationality} options={nationality} placeholder={t("form.nationality")} onChange={(e) => setData(prevState => ({ ...prevState, nationality: e }))} />
                    <ReactSelect name="profession" label={t("form.profession")} required value={data.profession} options={profession} placeholder={t("form.profession")} onChange={(e) => setData(prevState => ({ ...prevState, profession: e }))} />
                    <ReactSelect name="gender" label={t("form.gender")} required options={gender} value={data.gender} placeholder={t("form.gender")} onChange={(e) => setData(prevState => ({ ...prevState, gender: e }))} />
                    <ReactSelect name="marital_status" label={t("form.maritalStatus")} required value={data.maritalStatus} options={maritalStatus} placeholder={t("form.maritalStatus")} onChange={(e) => setData(prevState => ({ ...prevState, maritalStatus: e }))} />
                    <label className="form-label">{t("form.bio")}</label>
                    <div className="col-md-12 border mt-0 mb-2 py-2 browers l">
                        <DraftEditor name="bio" data={data.bio} />
                    </div>
                    <SubmitButton noOffset={1} load={tinyloader} title={t("form.update")} action={submitForm} />
                </form>
            </div>
        </>
    )
}

export default Profile
import "./contact.scss"
import { useTranslation } from "react-i18next"
import Banner from "../../components/Partials/Banner"
import ContactForm from "./Components/ContactForm"
import ContactInfo from "./Components/ContactInfo"
import Info from "./Components/Info"
import Map from "./Components/Map"

const Contact = () => {
    const { t } = useTranslation();
    
    return (
        <div className="contact-section">
            <Banner title={t("nav.contact_us")} />
            <section className="section pt-0 bg-light">
                <Map />
                <Info />
            </section>
            <section className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h4 className="text-dark mb-0">{t("get_in_touch")}</h4>
                        </div>
                    </div>
                    <div className="row ">
                        <ContactForm />
                        <ContactInfo />
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Contact
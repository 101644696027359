import "./pricing-details.scss"
import { useTranslation } from "react-i18next"
import Banner from "../../components/Partials/Banner"
import { useLocation } from "react-router-dom"
import { useEffect, useState } from "react"
import { fetchData } from "../../components/Helpers/Helper"
import { PLAN } from "../../components/Helpers/ApiRoutes"

const PricingDetails = () => {
    const { t } = useTranslation();
    const [data, setData] = useState({});
    const location = useLocation();

    useEffect(() => {
        if (location && location.state && location.state.plan) {
            setData(location.state.plan)
        } else {
            window.history.back()
        }
    }, [location])

    useEffect(() => {
        if (location && location.state && location.state.plan) {
            fetchData(`${PLAN}/${location.state.plan.label}`, 'GET', '', false, true, (res) => {
                if (res.status) {
                    setData(res.body.plan)
                }
            })
        }
    }, [location])


    return (
        <div className="job-section job-details-section">
            <Banner title="Pricing Details" />
            <section className="ptb-100">
                <div className="container">
                    <div className="row justify-content-center align-item-center">
                        <div className="col-lg-8">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="job-details-text">
                                        <div className="details-text">
                                            <h3 className="text-center">{data.label}</h3>
                                        </div>
                                        <div className="details-text">
                                            <h3>{t("Packing")}</h3>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <table className="table">
                                                        <tbody>
                                                            <tr>
                                                                <td>Job Limit : {data.job_limit}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Featured Job Limit : {data.featured_job_limit}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Highlight Job Limit : {data.highlight_job_limit}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Candidate Cv Preview Limit : {data.candidate_cv_view_limit}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="details-text">
                                            <h3 className="text-center">{t("Description")}</h3>
                                            <div dangerouslySetInnerHTML={{ __html: data.description }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default PricingDetails
import { COMPANY_NOTIFICATION, NOTIFICATIONS, READ_NOTIFICATIONS } from "../../components/Helpers/ApiRoutes";
import { useTranslation } from "react-i18next"
import { AuthSection } from "../../components/Layouts/Layout";
import NotificationList from "../../components/Partials/NotificationList";
import { useEffect } from "react";
import { fetchData } from "../../components/Helpers/Helper";
import { isCandidate } from "../../components/Helpers/RoleHelper";

const Notification = () => {
    const { t } = useTranslation();

    useEffect(() => {
        fetchData(READ_NOTIFICATIONS, 'POST', '', true, false, (res) => {
            if (res.status) {
                document.getElementById("bell-notification-count").innerHTML = 0;
            }
        }, false, false, false, '', false)
    }, [])

    return (
        <AuthSection breadCrumbs={<span className="breadcrumb-item active">{t("nav.notifications")}</span>}>
            <NotificationList listUrl={isCandidate ? NOTIFICATIONS : COMPANY_NOTIFICATION} />
        </AuthSection>
    )
}

export default Notification
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router"
import { fetchData } from "../../components/Helpers/Helper"
import { VERIFY_EMAIL } from "../../components/Helpers/ApiRoutes";
import { useTranslation } from "react-i18next"

function VerifyEmail() {
    const { t } = useTranslation();
    const location = useLocation()
    const navigate = useNavigate();

    useEffect(() => {
        if (location.search) {
            fetchData(VERIFY_EMAIL + location.search, 'POST', '', false, false, (res) => {
                if (res.status) {
                    setTimeout(() => navigate('/sign-in'), 1000);
                }
            })
        } else {
            navigate('/')
        }
    }, [navigate, location])

    return (
        <>
            <section className="about-section py-5 ">
                <div className="container py-sm-4 py-5">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-sm-8 text-center">
                            <div className="account">
                            <h2 className="fw-bold">{t("account_verification")}</h2>
                            <p className="opacity-75 text-center">{t("we_are_verifying_your_account_text")}</p>
                            </div>
                       </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default VerifyEmail
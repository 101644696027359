import "./pricing.scss"
import { useTranslation } from "react-i18next"
import { fetchData } from "../../components/Helpers/Helper"
import Banner from "../../components/Partials/Banner"
import { useEffect, useState } from "react"
import { PLAN } from "../../components/Helpers/ApiRoutes"
import Pagination from "../../components/Helpers/Pagination"
import { Link } from "react-router-dom"

const Pricing = () => {
    const [data, setData] = useState({});
    const [currentPage, setCurrentPage] = useState(1)
    const { t } = useTranslation();

    useEffect(() => {
        fetchData(`${PLAN}?page=${currentPage}`, 'GET', '', false, true, (res) => {
            if (res.status) {
                setData(res.body)
            }
        })
    }, [currentPage])

    return (
        <div className="about-section">
            <Banner title="Pricing" />
            <section className="princing-section ptb-100">
                <div className="container">
                    <div className="">
                        <div className="pricing-table">
                            {data && data.data && data.data.length > 0 && data.data.map((item, key) => {
                                return (
                                    <div class={`ptable-item ${item.recommended && 'featured-item'}`} key={key}>
                                        <div className="ptable-single">
                                            <div className="ptable-header">
                                                {item.recommended && <div className="ptable-status"><span>{t("recommended")}</span></div>}
                                                <div className="ptable-title">
                                                    <h2>{item.label}</h2>
                                                </div>
                                                <div className="ptable-price">
                                                    <h2>{item.price}</h2>
                                                </div>
                                            </div>
                                            <div className="ptable-body">
                                                <div className="ptable-description">
                                                    <ul>
                                                        <li>{t("job_limit")} : {item.job_limit}</li>
                                                        <li>{t("featured")} {t("job_limit")} : {item.featured_job_limit}</li>
                                                        <li>{t("highlight")} {t("job_limit")} : {item.highlight_job_limit}</li>
                                                        <li>{t("candidate_cv_prev_limit")} : {item.candidate_cv_view_limit}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="ptable-footer">
                                                <div className="ptable-action">
                                                    <Link to="/pricing-details" className="" state={{ plan: item }}>{t("view_details")}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="col-md-12">
                            <Pagination paginate_data={data} onPageChange={(page) => setCurrentPage(page.selected >= 0 ? page.selected + 1 : 0)} />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Pricing
import "./blog.scss"
import { useTranslation } from "react-i18next"
import Banner from '../../components/Partials/Banner';
import { useEffect, useState } from "react";
import { fetchData } from "../../components/Helpers/Helper";
import { BLOG_DETAILS } from "../../components/Helpers/ApiRoutes";
import { useLocation } from "react-router-dom";

const BlogDetails = () => {
    const { t } = useTranslation();
    const [data, setData] = useState({});
    const location = useLocation();

    useEffect(() => {
        if (location && location.state && location.state.slug) {
            fetchData(`${BLOG_DETAILS}/${location.state.slug}`, 'GET', '', false, true, (res) => {
                if (res.status) {
                    setData(res.body)
                }
            })
        } else {
            window.history.back()
        }
    }, [location])


    return (
        <div className="blog-section">
            <Banner title={t("nav.blog")} />
            <section className="blog-section blog-style-two pt-100 pb-70">
                <div className="container">
                    <div className="section-title text-center">
                        <h2>{t("news_tips_articles")}</h2>
                        <p>{t("top_category_short_description")}</p>
                    </div>
                    <div className="row align-items-center justify-content-center">
                    <div className="col-sm-8">
                    <div className="blog-detail-images" >
                    <img src={data.image_url} className="w-100" alt="blog-detail"/>
                    </div>
                    
                    <div className="row blog-details d-flex align-items-center justify-content-center">
                        <div className="col-lg-12">
                            <div className="details-text">
                            <h3>{t("nav.about_us")}</h3>
                                <div className="posted-by">
                                   <p>{t("title")}: {data.title}</p>
                                   <p>{t("author")}: {data.author && data.author.name}</p>
                                   <p>{t("created_at")}: {data.created_at}</p>
                                <h3>{t("description")}</h3>
                                <div dangerouslySetInnerHTML={{ __html: data.description }}></div>
                            </div>
                        </div>
                    
                        </div>
                    </div>
                    </div>
                 
                    </div>
                
             
                </div>
            </section>
        </div>
    )
}

export default BlogDetails
import { AuthSection } from "../../components/Layouts/Layout";
import { useTranslation } from "react-i18next"
import { FAVOURITE_JOBS } from "../../components/Helpers/ApiRoutes";
import JobList from "../../components/Partials/JobList";

const FavouriteJob = () => {
    const { t } = useTranslation();

    return (
        <AuthSection breadCrumbs={<span className="breadcrumb-item active">{t("nav.favourite_job")}</span>}>
            <JobList listUrl={FAVOURITE_JOBS} from="fav" />
        </AuthSection>
    )
}

export default FavouriteJob
import { memo, useEffect, useState } from "react";
import { noImageHandle } from "../../../components/Helpers/Helper";

const UserDetails = (props) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (props.user) {
            setUser(props.user);
        }
    }, [props.user])

    return (
        <>
            <div id="chatify-reciever-header" className="chat-header clearfix1 user-details">
                <div className="row">
                    <div className="col-lg-6">
                        {user && user.fetch ? <>
                            <img src={user.fetch.image_url} alt="avatar" onError={noImageHandle} />
                            <div className="chat-about">
                                <h5 className="mb-0">{user.fetch.name}</h5>
                                <small className="ms-2 text-success chatify-user-status">{user.fetch.active_status ? "Online" : "Offline"}</small>
                                <small className="ms-2 text-success chatify-user-lastSeen" data-time={user.lastSeen_at}>{user.lastSeen && `last seen ${user.lastSeen}`}</small>
                                <small className="chatify-typing ms-2"></small>
                            </div>
                        </> : <>
                            <div className="chat-about opacity-0">
                                <h5 className="mb-0">User Info</h5>
                                <small>Online</small>
                            </div>
                        </>}
                    </div>
                    <div className="col-lg-6 hidden-sm text-right"></div>
                </div>
            </div>
        </>
    )
}

export default memo(UserDetails)

import { useTranslation } from "react-i18next";
import { fetchData, validateForm } from "../../../components/Helpers/Helper";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import Context from "../../../components/Helpers/Context";
import { LOGIN,GET_SERVER_MAIL } from "../../../components/Helpers/ApiRoutes";
import { UnAuthInputField, UnAuthPasswordField, UnAuthSubmitButton } from "../../../components/Helpers/FormHelper";
import { role } from "../../../components/Helpers/RoleHelper";

const SignInForm = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [, setContext] = useContext(Context)
    const [tinyloader, setTinyloader] = useState(false);

    const submitForm = (e) => {
        if (validateForm(e, 'login-form')) {
            setTinyloader(true)
            let formdata = new FormData(document.getElementById('login-form'));
            formdata.append('role', role);
            fetchData(LOGIN, 'POST', formdata, false, true, (res) => {
                setTinyloader(false)
                if (res.status) {
                    localStorage.setItem("recruitment-token", res.body.access_token);
                    setContext(prevState => ({
                        ...prevState,
                        auth: res.body,
                    }))
                    // fetchMailData();
                    if(res.body.user_details.profile_complete === 0) navigate("/dashboard");
                    else navigate("/setting");
                }
            }, false, false, false, "#login-form")
        }
    }

    const fetchMailData = () => {
        fetchData(`${GET_SERVER_MAIL}?page=1`, 'GET', '', true, true, (res) => {
        });
      };

    return (
        <>
            <form className="sign-in-form needs-validation" noValidate id="login-form">
                <h2 className="title">{t("nav.sign_in")}</h2>
                <UnAuthInputField label={t("form.email")} name="email" id="username" placeholder={t("form.email")} icon="bx bx-user" required />
                <UnAuthPasswordField label={t("form.password")} id="username_password" name="password" placeholder="********" icon="bx bx-lock" required />
                <UnAuthSubmitButton type="submit" load={tinyloader} title={t("nav.sign_in")} action={submitForm} />
                <Link to="/forgot-password" className="anchor-blueorange fw-medium" title={`${t("nav.forgot_password")}?`}>{t("nav.forgot_password")}?</Link>
                {/* <p className="social-text">{t("Or Sign in with social platforms")}</p> */}
            </form>
        </>
    )
}

export default SignInForm
import { useLocation } from "react-router-dom"
import { useEffect, useState } from "react"
import CandidateDetail from "./Components/CandidateDetail"

const CandidateDetails = () => {
    const [data, setData] = useState(null);
    const location = useLocation();

    useEffect(() => {
        if (location && location.state && location.state.data) {
            setData(location.state.data)
        } else {
            window.history.back()
        }
    }, [location])

    return (
        <CandidateDetail banner={true} data={data}/>
    )
}

export default CandidateDetails
import { useTranslation } from "react-i18next"
import Select from 'react-select'

export const showPassword = (e, id) => {
    let btn = e.target;
    let ele = document.getElementById(id);
    if (ele.getAttribute('type') === 'password') {
        ele.setAttribute('type', 'text');
        btn.innerHTML = '<i class="bi-eye-slash fs-5 pe-none"></i>'
    } else {
        ele.setAttribute('type', 'password');
        btn.innerHTML = '<i class="bi-eye fs-5 pe-none"></i>'
    }
}

export const removeError = (e) => {
    if (e.target.nextElementSibling && e.target.nextElementSibling.classList.contains('d-block')) e.target.nextElementSibling.remove()
    e.target.classList.remove('is-invalid')
    e.target.classList.add('is-valid')
}

export const removeReactSelectError = (id) => {
    let ele = document.querySelector(`#${id}`)
    if(ele.lastChild.classList.contains('d-block')) ele.lastChild.remove();
    ele.classList.remove('is-invalid')
    ele.classList.add('is-valid')
}

export const PasswordField = (props) => {
    const { t } = useTranslation();

    return (
        <div className="mb-3 row mx-0">
            <label htmlFor={props.name} className="col-sm-3 col-xxl-2 col-form-label">
                {props.label} {props.required && <sup className='text-danger fw-bold fs-15px'>*</sup>}
            </label>
            <div className="col-sm-9 col-lg-6 col-xxl-5 position-relative">
                <input type="password" maxLength={20} className="form-control no-validate-icon" id={props.id ?? props.name} {...props} autoComplete="off" onChange={removeError} />
                {props.suggestion && <span className="text-success fs-12px">{props.suggestion}</span>}
                <div className="invalid-feedback">{t('validation.required', [props.label])}</div>
                <button type="button" onClick={(e) => showPassword(e, props.id ?? props.name)} className="eye-set pt-1">
                    <i className="bi-eye fs-5 pe-none"></i>
                </button>
            </div>
        </div>
    )
}

export const UnAuthPasswordField = (props) => {
    const { t } = useTranslation();

    return (
        <div className="input-group password-control mb-2">
            <div className="input-group-prepend">
                <div className="input-group-text">
                    <i className={props.icon}></i>
                </div>
            </div>
            <input type="password" id={props.id ?? props.name} className="form-control password-control" {...props} autoComplete="off" onChange={removeError} />
            <div className="invalid-feedback">{t('validation.required', [props.label])}</div>
            <div role="button" className="password-eye" onClick={(e) => showPassword(e, props.id ?? props.name)}>
                <i className="bi-eye fs-5 pe-none"></i>
            </div>
        </div>
    )
}

export const UnAuthInputField = (props) => {
    const { t } = useTranslation();

    return (
        <div className="input-group mb-2">
            <div className="input-group-prepend">
                <div className="input-group-text">
                    <i className={props.icon}></i>
                </div>
            </div>
            <input type={props.type ?? 'text'} id={props.id ?? props.name} className="form-control" {...props} autoComplete="off" onChange={removeError} />
            <div className="invalid-feedback">{t('validation.required', [props.label])}</div>

        </div>
    )
}

export const InputField = (props) => {
    const { t } = useTranslation();

    return (
        <div className={`mb-2 mx-0 ${props.col ? "col-sm-md col-lg-12" : "row"}`}>
            <label htmlFor={props.name} className={props.col ? "" : "col-sm-3 col-xxl-2 col-form-label"}>
                {props.label} {props.required && <sup className='text-danger fw-bold fs-15px'>*</sup>}
            </label>
            <div className={props.col ? "" : "col-sm-9 col-lg-6 col-xxl-5"}>
                <input type={props.type ?? 'text'} className="form-control" id={props.name} {...props} autoComplete="off" onChange={removeError} />
                {props.suggestion && <span className="text-success fs-12px">{props.suggestion}</span>}
                <div className="invalid-feedback">{t('validation.required', [props.label])}</div>
            </div>
        </div>
    )
}

export const ModalInputField = (props) => {
    const { t } = useTranslation();
    return (
        <div className="mb-4 row mx-0">
            <label htmlFor={props.name} className="col-sm-3 col-xxl-6 col-form-label">
                {props.label} {props.required && <sup className='text-danger fw-bold fs-15px'>*</sup>}
            </label>
            <div className="col-sm-9 col-lg-6 col-xxl-6">
                <input type={props.type ?? 'text'} className="form-control" id={props.name} {...props} autoComplete="off" onChange={removeError} />
                {props.suggestion && <span className="text-success fs-12px">{props.suggestion}</span>}
                <div className="invalid-feedback">{t('validation.required', [props.label])}</div>
            </div>
        </div>
    )
}

export const SelectField = (props) => {
    const { t } = useTranslation();
    return (
        <div className="mb-4 row mx-0">
            <label htmlFor={props.name} className="col-sm-3 col-xxl-2 col-form-label">
                {props.label} {props.required && <sup className='text-danger fw-bold fs-15px'>*</sup>}
            </label>
            <div className="col-sm-9 col-lg-6 col-xxl-5">
                {props.children}
                <div className="invalid-feedback">{t('validation.required', [props.label])}</div>
            </div>
        </div>
    )
}

export const ModalSelectField = (props) => {
    const { t } = useTranslation();
    return (
        <div className="mb-4 row mx-0">
            <label htmlFor={props.name} className="col-sm-3 col-xxl-6 col-form-label">
                {props.label} {props.required && <sup className='text-danger fw-bold fs-15px'>*</sup>}
            </label>
            <div className="col-sm-9 col-lg-6 col-xxl-6">
                {props.children}
                <div className="invalid-feedback">{t('validation.required', [props.label])}</div>
            </div>
        </div>
    )
}

export const UnAuthSubmitButton = (props) => {
    const { t } = useTranslation();
    return (
        <div className="mb-2">
            <button type={props.type ?? "button"} className={`btn dark`} title={props.title} onClick={props.action ?? null}>
                {props.load ? <div className="spinner-border spinner-border-sm mx-3" role="status"><span className="visually-hidden">{t("loading")}</span></div> : props.title}
            </button>
        </div>
    )
}

export const SubmitButton = (props) => {
    const { t } = useTranslation();
    return (
        <div className="row mx-0">
            <div className={`col-sm-10 px-0 ${!props.noOffset && 'offset-sm-3 offset-xxl-2'}`}>
                <button type={props.type ?? "button"} className={`btn btn-${props.bgColor ?? 'primary'} w-120px px-4 me-2`} title={props.title} onClick={props.action}>
                    {props.load ? <div className="spinner-border spinner-border-sm mx-3" role="status"><span className="visually-hidden">{t("loading")}</span></div> : props.title}
                </button>
            </div>
        </div>
    )
}


export const ModalSubmitButton = (props) => {
    const { t } = useTranslation();
    return (
        <div className="row mx-0">
            <div className="col-6 offset-sm-3 offset-xxl-6">
                <button type={props.type ?? "button"} className={`btn btn-${props.bgColor ?? 'blue'} w-120px px-4 me-2`} title={props.title} onClick={props.action ?? null}>{props.load ? <div className="spinner-border spinner-border-sm mx-3" role="status"><span className="visually-hidden">{t("loading")}</span></div> : props.title}
                </button>
            </div>
        </div>
    )
}

export const ReactSelect = (props) => {
    return (
        <div className={props.size ? props.size :'col-md-6'}>
            <label htmlFor={props.name} className="form-label">
                {props.label} {props.required && <sup className='text-danger fw-bold fs-15px'>*</sup>}
            </label>
            <Select  className="react-select" id={props.id ?? props.name} onInputChange={() => removeReactSelectError(props.id ?? props.name)} {...props} />
        </div>
    )
}
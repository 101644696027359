import { useEffect } from "react";
import { useTranslation } from "react-i18next"
import { noImageHandle } from "../../../components/Helpers/Helper";
import { toInteger } from "lodash";

const TestimonialSection = ({ data }) => {
    const { t } = useTranslation();

    useEffect(() => {
        let index = 0;
        let intervel = setInterval(() => {
            index = index > 2 ? 0 : index;
            document.querySelectorAll(".testimonial .tabs li").forEach(ele => {
                if ((toInteger(ele.dataset.slideTo) === index)) ele.click();
            })
            index++;
        }, 3000);
        return (() => clearInterval(intervel));
    }, [])

    const imageClick = (index) => {
        document.querySelectorAll(".testimonial .tabs li").forEach(ele => {
            if ((toInteger(ele.dataset.slideTo) === index)) {
                ele.classList.add('active');
            } else {
                ele.classList.remove('active');
            }
        })
    }
    return (
        <>
            {data && data.length > 0 &&
                <section className="testimonial  pt-100 mb-4 ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6  d-lg-block">
                                <ol className="carousel-indicators tabs">
                                    {data.map((item, key) => {
                                        return (
                                            <li onClick={() => imageClick(key)} key={key} data-target="#carouselExampleIndicators" data-slide-to={key} className={key === 0 ? "active" : ''}>
                                                <figure>
                                                    <img src={process.env.REACT_APP_ASSET_URL + item.image} className="img-fluid testimonal-image" alt="" onError={noImageHandle} />
                                                </figure>
                                            </li>
                                        )
                                    })}
                                </ol>
                            </div>
                            <div className="col-lg-6 d-flex justify-content-center align-items-center">
                                <div id="carouselExampleIndicators" data-interval="false" className="carousel slide" data-ride="carousel">
                                    <h3>{t("what_aur_client_say")}</h3>
                                    <h1>{t("nav.testimonials")}</h1>
                                    <div className="carousel-inner">
                                        {data.map((item, key) => {
                                            return (
                                                <div key={key} className={`carousel-item ${key === 0 ? "active" : ''}`}>
                                                    <div className="quote-wrapper">
                                                        <p>{item.description}</p>
                                                        <h3>{item.name}</h3>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    )
}

export default TestimonialSection
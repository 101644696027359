import { useEffect, useState } from "react";
import { ALL_CATEGORY } from "../../../components/Helpers/ApiRoutes";
import { fetchData } from "../../../components/Helpers/Helper";
import Banner from "../../../components/Partials/Banner";
import Category from "../../../components/Partials/Category";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Pagination from "../../../components/Helpers/Pagination";

const AllCategory = () => {
  const [category, setCategory] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const { t } = useTranslation();

  useEffect(() => {
    fetchData(
      `${ALL_CATEGORY}?page=${currentPage}`,
      "GET",
      "",
      false,
      true,
      (res) => {
        if (res.status) {
          setCategory(res.body);
        }
      }
    );
  }, [currentPage]);
  
  return (
    <>
      <Banner />
      {category && category?.data?.length && (
        <section className="categories-section pt-100">
          <div className="container">
            <div className="section-title text-center">
              <h2>{t("all_category")}</h2>
            </div>
            <div className="row">
              {category?.data?.map((category, key) => (
                <Category
                  key={key}
                  title={category.name}
                  icon={category.icon}
                  disc={`${category.open_jobs_count} ${t("open position")}`}
                />
              ))}
            </div>
            <div className="col-md-12">
              <Pagination
                paginate_data={category}
                onPageChange={(page) =>
                  setCurrentPage(page.selected >= 0 ? page.selected + 1 : 0)
                }
              />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default AllCategory;

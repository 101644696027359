import { UnAuthInputField, UnAuthSubmitButton } from "../../../components/Helpers/FormHelper"
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchData, validateForm } from "../../../components/Helpers/Helper";
import { useLocation, useNavigate } from "react-router-dom";
import { RESET_PASSWORD } from "../../../components/Helpers/ApiRoutes";

const ResetPassword = () => {
    const { t } = useTranslation();
    const [tinyloader, setTinyloader] = useState(false);
    const navigate = useNavigate();
    const location = useLocation()

    const submitForm = (e) => {
        if (validateForm(e, "reset-password-form")) {
            setTinyloader(true)
            let formdata = new FormData(document.getElementById('reset-password-form'));
            fetchData(RESET_PASSWORD + location.search, 'POST', formdata, false, true, (res) => {
                setTinyloader(false)
                if (res.status) {
                    navigate('/sign-in')
                }
            }, false, false, false, "#reset-password-form")
        }
    }

    return (
        <>
            <form action="#" noValidate className="needs-validation sign-up-form" id="reset-password-form">
                <h2 className="title">{t("nav.reset_password")}</h2>
                <UnAuthInputField label={t("form.password")} type="password" name="password" placeholder="********" icon="bx bx-lock" required />
                <UnAuthInputField label={t("form.confirm_password")} type="password" name="password_confirmation" placeholder="********" icon="bx bx-lock" required />
                <UnAuthSubmitButton type="submit" load={tinyloader} title={t("form.submit")} action={submitForm} />
            </form>
        </>
    )
}

export default ResetPassword
import { PasswordField, SubmitButton } from "../../components/Helpers/FormHelper"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import { fetchData, initialFormState, validateForm } from "../../components/Helpers/Helper"
import { CHANGE_PASSWORD } from "../../components/Helpers/ApiRoutes"
// import { AuthSection } from "../../components/Layouts/Layout"

const ChangePassword = () => {
    const { t } = useTranslation();
    const [tinyloader, setTinyloader] = useState(false);

    const submitForm = (e) => {
        if (validateForm(e)) {
            setTinyloader(true)
            let formdata = new FormData(document.getElementById('change-password-form'))
            fetchData(CHANGE_PASSWORD, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.status) {
                    initialFormState("change-password-form")
                }
            })

        }
    }

    return (
        // <AuthSection breadCrumbs={<span className="breadcrumb-item active">{("change_password")}</span>}>
            <form id="change-password-form" action="#" method="post" className="needs-validation" noValidate>
                <PasswordField label={t("form.password")} name="password" required />
                <PasswordField label={t("form.new_password")} name="new_password" required />
                <PasswordField label={t("form.confirm_password")} name="confirm_password" required />
                <SubmitButton load={tinyloader} title={t("Update")} action={submitForm} />
            </form>
        // </AuthSection>
    )
}

export default ChangePassword
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"
import Select from 'react-select'

const SearchHeader = (props) => {
    const { t } = useTranslation();
    const [role, setRole] = useState(null);
    const [query, setQuery] = useState({ job_role: '', job_type: '', country: '', keyword: '' });

    useEffect(() => {
        if (localStorage.getItem('job_filter')) {
            let job_filter = JSON.parse(localStorage.getItem('job_filter'));
            setRole(job_filter.job_roles ?? null)
            setQuery({
                job_role: job_filter.job_roles && job_filter.job_roles.value ? job_filter.job_roles.value : '',
                job_type: '', country: job_filter.location ?? '', keyword: job_filter.keyword ?? ''
            });
        }
    }, [])

    const filterJobs = () => {
        props.setQuery(query)
        // if(query.job_role || query.job_type || query.country || query.keyword) props.setQuery(query)
        // else showAlert({message: t("please_fill_some_record"), status:false})
    }

    return (
        <>
            <div className="search-menu">
                <div className="search-bar">
                    <Select className="jobFilterSelect" isClearable name="job_roles" options={props.data && props.data.job_roles} value={role} placeholder={t("job_role")} onChange={(e) => { setRole(e); setQuery(prev => ({ ...prev, job_role: e ? e.label : '' })) }} />
                </div>
                <div className="search-bar">
                    <Select className="jobFilterSelect" isClearable name="job_types" options={props.data && props.data.job_types} placeholder={t("job_type")} onChange={(e) => setQuery(prev => ({ ...prev, job_type: e ? e.label : '' }))} />
                </div>
                <div className="search-location">
                    <i className="fa fa-map me-2"></i>
                    <input type="text" placeholder={t("country")} className="search-box" value={query.country} onChange={(e) => setQuery(prev => ({ ...prev, country: e.target.value }))} />
                </div>
                <div className="search-salary">
                    <i className="fa fa-building me-2"></i>
                    <input type="text" placeholder={t("Job_keyword")} className="search-box" value={query.keyword} onChange={(e) => setQuery(prev => ({ ...prev, keyword: e.target.value }))} />
                </div>
                <button className="search-button" onClick={filterJobs}>{t("find_a_job")}</button>
            </div>
        </>
    )
}

export default SearchHeader
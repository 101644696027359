import { UnAuthInputField, UnAuthSubmitButton } from "../../../components/Helpers/FormHelper"
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchData, initialFormState, validateForm } from "../../../components/Helpers/Helper";
import { REGISTER } from "../../../components/Helpers/ApiRoutes";
import { role } from "../../../components/Helpers/RoleHelper";

const SignUpForm = () => {
    const { t } = useTranslation();
    const [tinyloader, setTinyloader] = useState(false);

    const submitForm = (e) => {
        if (validateForm(e, 'sign-up-form')) {
            setTinyloader(true)
            let formdata = new FormData(document.getElementById('sign-up-form'));
            formdata.append('role', role);
            fetchData(REGISTER, 'POST', formdata, false, true, (res) => {
                setTinyloader(false)
                if (res.status) {
                    initialFormState('sign-up-form')
                }
            }, false, false, false, "#sign-up-form")
        }
    }

    return (
        <>
            <form action="#" noValidate className="needs-validation sign-up-form" id="sign-up-form">
                <h2 className="title">{t("nav.sign_up")}</h2>
                <UnAuthInputField label={t("form.name")} name="name" placeholder={t("form.name")} icon="bx bx-user" required />
                <UnAuthInputField label={t("form.email")} name="email" placeholder={t("form.email")} icon="bx bx-user" required />
                <UnAuthInputField label={t("form.password")} type="password" name="password" placeholder="********" icon="bx bx-lock" required />
                <UnAuthInputField label={t("form.confirm_password")} type="password" name="password_confirmation" placeholder="********" icon="bx bx-lock" required />
                <UnAuthSubmitButton type="submit" load={tinyloader} title={t("nav.sign_up")} action={submitForm} />
                {/* <p className="social-text">{t("Or Sign up with social platforms")}</p> */}
            </form>
        </>
    )
}

export default SignUpForm
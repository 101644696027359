import { useEffect, useState } from "react";
import DataTables, { reloadDataTable } from "../Helpers/Datatables";
import { createRoot } from 'react-dom/client'
import { noImageHandle } from "../Helpers/Helper";

const VisiterLogList = (props) => {

    const [dt] = useState({
        dt_url: props.listUrl,

        dt_name: 'visitor-log-list',
        dt_column: [
            { data: "DT_RowIndex", name: 'id', title: "", sortable: false, searchable: false, className: "noExport" },
            { data: "visiter.image_url", name: 'visiter.image_url', title: "logo", sortable: false, searchable: false },
            { data: "visiter.name", name: 'visiter.name', title: "Visitor", sortable: true, searchable: true },
            { data: "visiter.email", name: 'visiter.email', title: "Visitor Email", sortable: true, searchable: true },
            { data: "created_at", name: 'created_at', title: "Visited At" },            
        ],
        //display logo
        dt_column_defs: [
            {
                targets: 1,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                        <img src={records.visiter.image_url} className="border rounded-circle" style={{ width: "65px", height: "65px" }} alt="logo" onError={noImageHandle} />
                    </>)
                }
            },
        ],        
    })

    useEffect(() => {
        reloadDataTable(dt, props.listUrl)
    }, [dt, props.listUrl])

    return (
   
        <DataTables dt={dt} dt_name="visitor-log-list" />
    )
}

export default VisiterLogList
import { AuthSection } from "../../components/Layouts/Layout";
import { useTranslation } from "react-i18next"
import "./setting.scss"
import Basic from "./Components/Basic";
import Profile from "./Components/Profile";
import SocialMedia from "./Components/SocialMedia";
import AccountSetting from "./Components/AccountSetting";
import { useContext, useEffect, useState } from "react";
import Context from "../../components/Helpers/Context";
import { isCandidate, isCompany } from "../../components/Helpers/RoleHelper";
import CompanyInfo from "./Components/CompanyInfo";
import FoundationInfo from "./Components/FoundationInfo";
import ChangePassword from "../Auth/ChangePassword";
const Setting = () => {
    const { t } = useTranslation();
    const [profileDone, setProfileDone] = useState(100);
    const [context] = useContext(Context);

    useEffect(() => {
        if (context && context.auth && context.auth.user_details) {
            setProfileDone(context.auth.user_details.profile_complete);
        }
    }, [context])

    return (
        <AuthSection breadCrumbs={<span className="breadcrumb-item active">{t("nav.setting")}</span>}>
            {profileDone !== 0 && <p className="m-0 text-center text-success">{t("please_complete_your_profile_first")}</p>}
            <div className="row">
                <div className="col-sm-12">
                    <div className="py-4">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            {isCandidate && <>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">
                                        <i className="fa fa-user me-2" aria-hidden="true"></i>{t("basic")}
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button disabled={profileDone > 75} className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">
                                        <i className="fa fa-user me-2" aria-hidden="true"></i>{t("profile")}
                                    </button>
                                </li>
                            </>}
                            {isCompany && <>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="company-tab" data-bs-toggle="tab" data-bs-target="#company-tab-pane" type="button" role="tab" aria-controls="company-tab-pane" aria-selected="true">
                                        <i className="fa fa-user me-2" aria-hidden="true"></i>{t("company_info")}
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button  className="nav-link" id="foundation-tab" data-bs-toggle="tab" data-bs-target="#foundation-tab-pane" type="button" role="tab" aria-controls="foundation-tab-pane" aria-selected="false">
                                        <i className="fa fa-user me-2" aria-hidden="true"></i>{t("foundation_info")}
                                    </button>
                                </li>
                            </>}
                            <li className="nav-item" role="presentation">
                                <button disabled={profileDone > 50} className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">
                                    <i className="fa fa-globe  me-2" aria-hidden="true"></i>{t("social_media")}
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button disabled={profileDone > 25} className="nav-link" id="disabled-tab" data-bs-toggle="tab" data-bs-target="#disabled-tab-pane" type="button" role="tab" aria-controls="disabled-tab-pane" aria-selected="false">
                                    <i className="fa fa-cog  me-2" aria-hidden="true"></i>{t("account_setting")}
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="change-password-tab" data-bs-toggle="tab" data-bs-target="#change-password-tab-pane" type="button" role="tab" aria-controls="change-password-tab-pane" aria-selected="false">
                                    <i className="fa fa-key  me-2" aria-hidden="true"></i>{t("change_password")}
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            {isCandidate && <>
                                <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                                    <Basic />
                                </div>
                                <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">
                                    <Profile />
                                </div>
                            </>}
                            {isCompany && <>
                                <div className="tab-pane fade show active" id="company-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                                    <CompanyInfo />
                                </div>
                                <div className="tab-pane fade" id="foundation-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">
                                    <FoundationInfo />
                                </div>
                            </>}
                            <div className="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabIndex="0">
                                <SocialMedia />
                            </div>
                            <div className="tab-pane fade" id="disabled-tab-pane" role="tabpanel" aria-labelledby="disabled-tab" tabIndex="0">
                                <AccountSetting />
                            </div>
                            <div className="tab-pane fade" id="change-password-tab-pane" role="tabpanel" aria-labelledby="disabled-tab" tabIndex="0">
                                <ChangePassword />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AuthSection>
    )
}

export default Setting
import { AuthSection } from "../../components/Layouts/Layout";
import { useTranslation } from "react-i18next"
import { APPLIED_JOBS } from "../../components/Helpers/ApiRoutes";
import JobList from "../../components/Partials/JobList";

const AppliedJob = () => {
    const { t } = useTranslation();

    return (
        <AuthSection breadCrumbs={<span className="breadcrumb-item active">{t("applied_job")}</span>}>
        <JobList listUrl={APPLIED_JOBS} />
        </AuthSection>
    )
}

export default AppliedJob
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next"
import { SubmitButton } from "../../../components/Helpers/FormHelper";
import { fetchData, initialFormState, validateForm } from "../../../components/Helpers/Helper";
import { GET_SOCIAL_LINK, UPDATE_SOCIAL_LINK } from "../../../components/Helpers/ApiRoutes";
import Context from "../../../components/Helpers/Context";

const SocialMedia = (props) => {
    const { t } = useTranslation();
    const [, setContext] = useContext(Context);
    const [tinyloader, setTinyloader] = useState(false);
    const [socialLink, setSocialLink] = useState(['facebook', 'twitter', 'linkedin', 'instagram', 'github', 'other']);
    const [added, setAdded] = useState([]);
    const [loop, setLoop] = useState(['']);

    useEffect(() => {
        fetchData(GET_SOCIAL_LINK, 'GET', '', true, false, (res) => {
            if (res.status && res.body && res.body.social_media && res.body.social_media.length > 0) {
                let links = res.body.social_media;
                let last_link = links.pop();
                setSocialLink(prevState => prevState.filter((item) => !links.includes(item)))
                setAdded(links)
                setLoop(res.body.urls)
                setTimeout(() => document.getElementById("addLink").value = last_link, 0)
            }
        })
    }, [])

    const addNew = () => {
        setLoop(prevState => ([...prevState, '']))
        let name = document.getElementById("addLink").value;
        setSocialLink(prevState => prevState.filter((item) => item !== name))
        setAdded(prevState => ([...prevState, name]))
    }

    const handleInputChange = (e, index) => {
        setLoop(prevState => {
            prevState[index] = e.target.value
            return prevState
        })
    }

    const removeInput = (key, last = false) => {
        let value = added[last ? key-1 : key];
        setSocialLink(prevState => ([...prevState, value]));
        setLoop(prevState => prevState.filter((item, index) => index !== key));
        setAdded(prevState => prevState.filter((item) => item !== value));
        if(last) setTimeout(() => document.getElementById("addLink").value = value, 0);
    }

    const submitForm = (e) => {
        if (validateForm(e, "social-form")) {
            setTinyloader(true)
            let name = document.getElementById("addLink").value;
            let social_media = [...added, name];
            fetchData(UPDATE_SOCIAL_LINK, 'POST', { social_media, url: loop }, true, false, (res) => {
                setTinyloader(false)
                setContext(prevState => ({ ...prevState, auth: res.body }))
                initialFormState('social-form', false)
            })
        }
    }

    return (
        <>
            <form id="social-form" action="#" method="post" className="needs-validation" noValidate>
                {loop && loop.length > 0 && loop.map((item, key) => {
                    return (
                        <div className="input-group mb-2" key={key}>
                            <div className="input-group-text">
                                {loop.length === key + 1 ? <>
                                    <select className="customSelect form-select" aria-label="Default select example" id="addLink">
                                        {socialLink.map((item, key) => <option key={key} value={item}>{item}</option>)}
                                    </select>
                                </> : added[key]}
                            </div>
                            <input required type="url" className="form-control" defaultValue={item} aria-label="Text input with 2 dropdown buttons" placeholder="https://www.jaquhecebe.me" onChange={(e) => handleInputChange(e, key)} />
                            <div className="invalid-feedback">{t("validation.url")}</div>
                            {loop.length !== 1 && <>
                                <button className="btn btn-outline-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => removeInput(key, (loop.length === key + 1))}>
                                    <i className="bi bi-x-circle-fill px-1"></i>
                                </button>
                            </>}
                        </div>
                    )
                })}
                <div className="col-12 text-center">
                    <button className="btn btn-primary mt-4 add-new-social" type="button" onClick={addNew} disabled={socialLink.length === 1}>
                        <i className="bi bi-plus-circle-fill me-2"></i>
                        <span>{t("add_new_social_link")}</span>
                    </button>
                </div>
                <SubmitButton noOffset={1} load={tinyloader} title={t("form.update")} action={submitForm} />
            </form>
        </>
    )
}

export default SocialMedia
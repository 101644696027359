import { useTranslation } from "react-i18next"
import { ReactSelect, SubmitButton } from "../../../components/Helpers/FormHelper";
import { useContext, useEffect, useState } from "react";
import Context from "../../../components/Helpers/Context";
import { fetchData } from "../../../components/Helpers/Helper";
import { GET_JOB_ROLE, UPDATE_JOB_ALERT } from "../../../components/Helpers/ApiRoutes";

const AccountAccess = (props) => {
    const { t } = useTranslation();
    const [context, setContext] = useContext(Context);
    const [tinyloader, setTinyloader] = useState(false);
    const [role, setRole] = useState([]);
    const [access, setAccess] = useState({ "role": null, "received_job_alert": null, "visibility": null, "cv_visibility": null, 'recent_activities_alert': null, 'job_expired_alert': null, 'new_job_alert': null, 'shortlisted_alert': null });

    useEffect(() => {
        if (context && context.auth && context.auth.user_details) {
            setAccess(prevState => ({
                ...prevState,
                "received_job_alert": context.auth.user_details.received_job_alert ?? 0,
                "visibility": context.auth.user_details.visibility ?? 0,
                "cv_visibility": context.auth.user_details.cv_visibility ?? 0,
                'recent_activity': context.auth.recent_activities_alert,
                'job_expired': context.auth.job_expired_alert,
                'new_job': context.auth.new_job_alert,
                'shortlisted': context.auth.shortlisted_alert
            }))

            fetchData(GET_JOB_ROLE, 'GET', '', false, true, (res) => {
                if (res.status) {
                    setRole(res.body)
                    if (context && context.auth && context.auth.user_details) {
                        let role = res.body.filter((item) => item.value === context.auth.user_details.role_id)
                        setAccess(prevState => ({ ...prevState, role: role[0] ? role[0] : res.body[0] }))
                    }
                }
            })
        }
    }, [context])

    const submitAccessForm = (e) => {
        let formdata = { ...access, role_id: access.role.value }
        setTinyloader(true)
        fetchData(UPDATE_JOB_ALERT, 'POST', formdata, true, false, (res) => {
            setTinyloader(false)
            if (res.status) {
                setContext(prevState => ({ ...prevState, auth: res.body }))
            }
        })
    }

    return (
        <>
            <div className="col-lg-12 border-bottom mt-4">
                <h6 className="phone mb-4">Account Access</h6>
                <form id="access-setting-form" action="#" method="post" className="row g-3 needs-validation mb-2" noValidate>
                    <div className="row mb-4">
                        <div className="col-sm-6">
                            <div className="w-100">
                                <ReactSelect name="role" label={t("Role")} required value={access.role} options={role} placeholder={t("Role")} onChange={(e) => setAccess(prevState => ({ ...prevState, role: e }))} />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-check form-switch pt-4 job-arbic">
                                <input className="form-check-input " type="checkbox" role="switch" id="job_alert" checked={access.received_job_alert ? true : false} onChange={(e) => setAccess(prevState => ({ ...prevState, received_job_alert: e.target.checked ? 1 : 0 }))} />
                                <label className="form-check-label" htmlFor="job_alert">Job Alert</label>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4 px-4">
                        <div className="col-sm-6">
                            <div className="form-check form-switch  job-arbic">
                                <input className="form-check-input" type="checkbox" role="switch" id="profile_privacy" checked={access.visibility ? true : false} onChange={(e) => setAccess(prevState => ({ ...prevState, visibility: e.target.checked ? 1 : 0 }))} />
                                <label className="form-check-label" htmlFor="profile_privacy">Profile Privacy Alert</label>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-check form-switch job-arbic">
                                <input className="form-check-input" type="checkbox" role="switch" id="resume_privacy" checked={access.cv_visibility ? true : false} onChange={(e) => setAccess(prevState => ({ ...prevState, cv_visibility: e.target.checked ? 1 : 0 }))} />
                                <label className="form-check-label" htmlFor="resume_privacy">Resume Privacy Alert</label>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4 px-4">
                        <div className="col-sm-6">
                            <div className="form-check form-switch  job-arbic">
                                <input className="form-check-input" type="checkbox" role="switch" id="recent_activity" checked={access.recent_activity ? true : false} onChange={(e) => setAccess(prevState => ({ ...prevState, recent_activity: e.target.checked ? 1 : 0 }))} />
                                <label className="form-check-label" htmlFor="recent_activity">Recent Activities Alert</label>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-check form-switch job-arbic">
                                <input className="form-check-input" type="checkbox" role="switch" id="job_expired" checked={access.job_expired ? true : false} onChange={(e) => setAccess(prevState => ({ ...prevState, job_expired: e.target.checked ? 1 : 0 }))} />
                                <label className="form-check-label" htmlFor="job_expired">Job Expired Alert</label>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4 px-4">
                        <div className="col-sm-6">
                            <div className="form-check form-switch  job-arbic">
                                <input className="form-check-input" type="checkbox" role="switch" id="new_job" checked={access.new_job ? true : false} onChange={(e) => setAccess(prevState => ({ ...prevState, new_job: e.target.checked ? 1 : 0 }))} />
                                <label className="form-check-label" htmlFor="new_job">New Job Alert</label>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-check form-switch job-arbic">
                          <input className="form-check-input" type="checkbox" role="switch" id="shortlisted" checked={access.shortlisted ? true : false} onChange={(e) => setAccess(prevState => ({ ...prevState, shortlisted: e.target.checked ? 1 : 0 }))} />
                                <label className="form-check-label" htmlFor="shortlisted">Shortlisted Alert</label>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4 px-4">
                        <div className="col-sm-6">
                            <div className="form-check form-switch  job-arbic">
                                <input className="form-check-input" type="checkbox" role="switch" id="profile_privacy" checked={access.visibility ? true : false} onChange={(e) => setAccess(prevState => ({ ...prevState, visibility: e.target.checked ? 1 : 0 }))} />
                                <label className="form-check-label" htmlFor="profile_privacy">Profile Privacy</label>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-check form-switch job-arbic">
                                <input className="form-check-input" type="checkbox" role="switch" id="resume_privacy" checked={access.cv_visibility ? true : false} onChange={(e) => setAccess(prevState => ({ ...prevState, cv_visibility: e.target.checked ? 1 : 0 }))} />
                                <label className="form-check-label" htmlFor="resume_privacy">Resume Privacy</label>
                            </div>
                        </div>
                    </div>
                    <SubmitButton noOffset={1} load={tinyloader} title={t("form.update")} action={submitAccessForm} />
                </form>
            </div>
        </>
    )
}

export default AccountAccess
import { useNavigate } from "react-router-dom"

const Tiles = (props) => {
    const navigate = useNavigate();
    
    return (
        <>
            <div className="col-lg-3 col-md-6 col-sm-6" role={props.navigate && "button"} onClick={() => props.navigate ? navigate(props.navigate) : null}>
                <div className="card top_widget">
                    <div className="body">
                        <div className="icon"><i className={props.icon}></i> </div>
                        <div className="content">
                            <div className="text mb-2 text-uppercase">{props.title}</div>
                            <h4 className="number mb-0">{props.count}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Tiles

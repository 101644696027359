const ContactForm = () => {
    return (
        <>
            <div className="col-lg-8 col-md-7 mt-4 pt-2">
                <div className="custom-form rounded border p-4">
                    <div id="message"></div>
                    <form method="post" action="php/contact.php" name="contact-form" id="contact-form">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group app-label">
                                    <label className="text-muted">Name</label>
                                    <input name="name" id="name2" type="text" className="form-control resume"
                                        placeholder="Enter Name.." />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group app-label">
                                    <label className="text-muted">Email address</label>
                                    <input name="email" id="email1" type="email" className="form-control resume"
                                        placeholder="Enter Email.." />
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group app-label">
                                    <label className="text-muted">Subject</label>
                                    <input type="text" className="form-control resume" id="subject"
                                        placeholder="Subject.." />
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group app-label">
                                    <label className="text-muted">Message</label>
                                    <textarea name="comments" id="comments" rows="3" cols="5" className="form-control resume"
                                        placeholder="Message.."></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <input type="button" id="submit" name="send" className="submitBnt btn btn-primary"
                                    value="Send Message" />
                                <div id="simple-msg"></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ContactForm

import { memo, useEffect, useState } from "react";
import { chatifyfetcher } from "../../../components/Helpers/Helper";
import { CHATIFY } from "../../../components/Helpers/ApiRoutes";

const UserList = (props) => {
    const [search, setSearch] = useState("");
    const [userSuggestion, setUserSuggestion] = useState("");

    useEffect(() => {
        if (search) {
            const controller = new AbortController();
            const signal = controller.signal;
            chatifyfetcher(`${CHATIFY.search}?search=${search}`, 'GET', '', true, true, (res) => {
                setUserSuggestion(res.records);
                setTimeout(() => {
                    document.querySelectorAll("#search-user table").forEach(element => {
                        element.addEventListener("click", () => {
                            let user = document.querySelector(`ul.chat-list li[data-contact="${element.dataset.contact}"]`);
                            if (user) user.click();
                        });
                    })
                }, 0);
            }, signal)
            return () => controller.abort()
        } else setUserSuggestion("");
    }, [search])

    useEffect(() => {
        if (userSuggestion) {
            function clickUser(element) {
                let user = document.querySelector(`ul.chat-list li[data-contact="${element.dataset.contact}"]`);
                if (user) user.click();
            }

            document.querySelectorAll("#search-user table").forEach(element => element.removeEventListener("click", () => clickUser(element)));
            document.querySelectorAll("#search-user table").forEach(element => element.addEventListener("click", () => clickUser(element)))
        }
    }, [userSuggestion])

    return (
        <>
            <div id="plist" className="people-list " >
                <div className="searchHeadereBar">
                    <section className="main">
                        <div className="search input-group">
                            <input type="text" name="q" placeholder="Search..." autoComplete="off" onChange={(e) => setSearch(e.target.value)} />
                            {userSuggestion && <div onClick={() => setUserSuggestion("")} id="search-user" className={search ? "results showResults" : "results"} dangerouslySetInnerHTML={{ __html: userSuggestion }}></div>}
                        </div>
                    </section>
                </div>
                {props.users && <ul className="list-unstyled chat-list mt-2 mb-0 fixedScroll " dangerouslySetInnerHTML={{ __html: props.users }}></ul>}
            </div>
        </>
    )
}

export default memo(UserList)

import { memo, useEffect, useState } from "react";
import { noImageHandle } from "../../../components/Helpers/Helper";
import { now } from "lodash";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
TimeAgo.addLocale(en)

const UserDetails = (props) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (props.user) {
            console.log(props.user, props.user.lastSeen_at);
            setUser(props.user);
            if (!props.user.active) {
                var timeInterval = setInterval(() => {
                    let time = props.user.lastSeen_at ? new Date(props.user.lastSeen_at).getTime() : new Date().getTime();
                    let timeAgo = new TimeAgo('en-US');
                    let diff_seconds = now() - time;
                    let diff_formatted = timeAgo.format(now() - diff_seconds);
                    let element = document.querySelector("#user-last-seen-time");
                    if (element) {
                        element.innerHTML = diff_formatted;
                    }
                }, 6000);
            } else {
                if (timeInterval) {
                    clearInterval(timeInterval);
                }
            }
        }
    }, [props.user])

    return (
        <>
            <div className="chat-header clearfix1 user-details">
                <div className="row">
                    <div className="col-lg-6">
                        {user && user.fetch ? <>
                        <div className="d-flex">
                           <div className="d-flex d-md-none justify-content-center align-items-center cursorPointer">
                            <i class="fa-solid fa-arrow-left" onClick={()=>props.setMobileCheck(false)}></i>
                           </div>
                            <img src={user.fetch.image_url} alt="avatar" onError={noImageHandle} />
                            <div className="chat-about">
                                <h5 className="mb-0">{user.fetch.name}</h5>
                                {user.active ? <small className="ms-2 text-success"><b>Online</b> {user.typing && 'typing...'}</small> : <>
                                    {user.lastSeen && <small id="user-last-seen-time">{`Last seen ${user.lastSeen}`}</small>}
                                </>}
                            </div>
                        </div>
                        </> : <>
                            <div className="chat-about opacity-0">
                                <h5 className="mb-0">User Info</h5>
                                <small>Online</small>
                            </div>
                        </>}
                    </div>
                    <div className="col-lg-6 hidden-sm text-right"></div>
                </div>
            </div>
        </>
    )
}

export default memo(UserDetails)

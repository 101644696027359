import { useTranslation } from "react-i18next";
import { downloadFile, fetchData, showAlert, } from "../../../components/Helpers/Helper";
import { DELETE_RESUME, DOWNLOAD_CV, RESUME_VIDEO_UPLOAD, UPDATE_CV } from "../../../components/Helpers/ApiRoutes";
import { useContext, useEffect, useState } from "react";
import Context from "../../../components/Helpers/Context";
import { ConfirmationModal, ModalSection } from "../../../components/Helpers/ModalHelper";

const MediaUpload = (props) => {
    const { t } = useTranslation();
    const [context, setContext] = useContext(Context);
    const [tinyloader, setTinyloader] = useState(false);
    const [tinyloader2, setTinyloader2] = useState(false);
    const [deleteResumeId, setDeleteResumeId] = useState();


    useEffect(() => {
        if (context && context.auth && context.auth.resumes_video && context.auth.resumes_video.id) {
            const video = document.getElementById("profile_video");
            const videoSource = document.createElement("source");
            videoSource.setAttribute("src", process.env.REACT_APP_ASSET_URL + context.auth.resumes_video.file);
            if(video){
                video.innerHTML = "";
                video.appendChild(videoSource);
                video.load();
            }
        }
    }, [context]);

    const uploadResume = (e) => {

        if (context?.auth?.resume_files.length >= 5) {
            showAlert({ message: t("you_can_not_upload_more_than_5_resume"), status: false });
        } else {

            if (e.target.files && e.target.files[0]) {
                let formdata = new FormData(document.getElementById("upload-resume"));
                if (context.auth && context.auth.resumes && context.auth.resumes.id)
                    setTinyloader(true);
                fetchData(UPDATE_CV, "POST", formdata, true, true, (res) => {
                    setTinyloader(false);
                    if (res.status) {
                        setContext((prevState) => ({ ...prevState, auth: res.body }));
                        removeError("upload-resume");
                    }
                });
            }
        }
    };

    const downloadResume = (id) => {
        fetchData(`${DOWNLOAD_CV}/${id}`, "GET", "", true, false,
            (file) => {
                downloadFile(file, "Resume");
            },
            false,
            "blob"
        );
    };

    const deleteResume = (id = false) => {
        let resume_id = id ? id : context.auth.resumes_video.id
        fetchData(`${DELETE_RESUME}?resume_id=${resume_id}&type=${id ? "doc" : "video"}`, "DELETE", "", true, false, (res) => {
            if (res.status) {
                setContext((prevState) => ({ ...prevState, auth: res.body }));
            }
        });
    };

    const uploadVideo = (e) => {
        let fSize = e.target.files[0].size; 
        let i=0;
        while(fSize>900){fSize/=1024;i++;}
        const originalSize = Math.round(fSize*100)/100
        if(originalSize > 15){
            showAlert({ message: "The resume video may not be greater than 15 mb!", status: false });
        }else{
            if (e.target.files && e.target.files[0]) {
                let formdata = new FormData(document.getElementById("upload-video"));
                if (
                    context.auth &&
                    context.auth.resumes_video &&
                    context.auth.resumes_video.id
                )
                    formdata.append("old_file_id", context.auth.resumes_video.id);
                setTinyloader2(true)
                fetchData(RESUME_VIDEO_UPLOAD, "POST", formdata, true, true, (res) => {
                    setTinyloader2(false)
                    if (res.status) {
                        setContext((prevState) => ({ ...prevState, auth: res.body }));
                        removeError("upload-video");
                    }
                });
            }
        }
    };

    const removeError = (form) => {
        document
            .querySelectorAll(`#${form} .invalid-feedback`)
            .forEach((e) => e.remove());
    };
    const stopVideo = () =>{
        const video = document.getElementById("profile_video");
        video.pause();
    }
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="main-profile col-sm-12 col-lg-12">
                        <div className="row">
                            <div className="col-md-12 mt-3">
                                <h3>Resume</h3>
                                <div className="main-pdf-box my-4">
                                    {
                                        !context?.auth?.resume_files?.length && (<>
                                            <div className="pdf-box">
                                                <div className="pdf-icon position-relative">
                                                    <img src="./images/pdf.png" alt="" />
                                                    <div className="show-button">
                                                        <div className="preview-btn">
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="text-center p-0 m-0">{"Please Upload"}</p>
                                            </div>
                                        </>)
                                    }
                                    {context?.auth?.resume_files?.map((resume, index) => {
                                        return (
                                            <>
                                                <div className="pdf-box " key={index}>
                                                    <div className="pdf-icon position-relative">
                                                        <img src="./images/pdf.png" alt="" />
                                                       
                                                        <div className="show-button">
                                                            <div className="preview-btn ">
                                                                <i class="fa-solid fa-trash" data-bs-toggle="modal" data-bs-target={`#resumeDelete`} onClick={() => setDeleteResumeId(resume?.id)}></i>
                                                                <i
                                                                    class="fa-solid fa-eye"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target={`#resumeModal_` + index}
                                                                ></i>
                                                                <i
                                                                    class="fa-solid fa-download"
                                                                    onClick={() => downloadResume(resume.id)}
                                                                ></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className="text-center p-0 m-0">{resume.name.substring(0, 20)}{resume.name.length > 20 && '...'}</p>
                                                </div>
                                            </>
                                        );
                                    })}
                                </div>
                                <div className="mt-3">
                                    <form id="upload-resume" action="#" method="post">
                                        <input
                                            id="upload"
                                            className="file-upload__input"
                                            type="file"
                                            name="cv"
                                            accept="application/pdf"
                                            onChange={uploadResume}
                                        />
                                        <label
                                            htmlFor="upload"
                                            className="file-upload__label mb-0 d-inline-block"
                                        >
                                            {tinyloader ? (
                                                <div
                                                    className="spinner-border spinner-border-sm mx-3"
                                                    role="status"
                                                >
                                                    <span className="visually-hidden">
                                                        {t("loading")}
                                                    </span>
                                                </div>
                                            ) : (
                                                t("upload_resume")
                                            )}
                                        </label>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-12 mt-5">
                                <h3 className="pb-2">Resume Video</h3>
                                <div className="main-pdf-box mt-4">
                                    <div className="pdf-box">
                                        <div className="pdf-icon">
                                            <img src="./images/play.png" alt="" />
                                            <div className="show-button">
                                                {context?.auth?.resumes_video?.id && <>
                                                    <div className="preview-btn">
                                                        <i class="fa-solid fa-trash" data-bs-toggle="modal" data-bs-target="#videoDelete"></i>
                                                        <i
                                                            class="fa-solid fa-eye"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#videoModal"

                                                        ></i>
                                                        <i class="fa-solid fa-download" onClick={()=>downloadResume(context.auth.resumes_video.id)}></i>
                                                    </div>
                                                </>}
                                            </div>
                                        </div>
                                        <p className="text-center p-0 m-0">Video</p>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <div className="w-100">
                                        <form id="upload-video" className="" action="#" method="post">
                                            <input type="file" id="upload_video_file" accept="video/*" name="resume_video" className="file-upload__input" onChange={uploadVideo} />
                                            <label htmlFor="upload_video_file" className="file-upload__label mb-0 d-inline-block">

                                                {tinyloader2 ? (
                                                    <div
                                                        className="spinner-border spinner-border-sm mx-3"
                                                        role="status"
                                                    >
                                                        <span className="visually-hidden">
                                                            {t("loading")}
                                                        </span>
                                                    </div>
                                                ) : (
                                                    t("upload_resume_video")
                                                )}
                                            </label>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {context?.auth?.resume_files?.map((resume, index) => {
                return (
                    <>
                        <ModalSection
                            modal_id={"resumeModal_" + index}
                            title="Resume"
                            size="xl"
                            key={index}
                        >
                            <iframe
                                className="priview_pdf"
                                src={process.env.REACT_APP_ASSET_URL + resume.file}
                                title="Resume 1"
                            ></iframe>
                        </ModalSection>
                    </>
                );
            })}
            <ModalSection modal_id="videoModal" title="Resume Video" size="xl" action={()=>stopVideo()}>
               
                    {context &&
                        context?.auth && context?.auth?.resumes_video &&
                        context?.auth?.resumes_video.id && (
                            <>
                                <video
                                    id="profile_video"
                                    width="100%"
                                    height="600"
                                    controls
                                ></video>
                            </>
                        )}
               
            </ModalSection>
            <ConfirmationModal size={"l"} modal_id="resumeDelete" title="Delete Resume" action={() => deleteResume(deleteResumeId)}>
            </ConfirmationModal>
            <ConfirmationModal modal_id="videoDelete" title="Delete Resume" action={() => deleteResume()}>
                <p className="">Are you sure want to delete this item?</p>
            </ConfirmationModal>
        </>
    );
};

export default MediaUpload;

import { memo, useEffect, useRef, useState } from "react";

const Messages = (props) => {
    const messagesEndRef = useRef(null);
    const [messages, setMessages] = useState("");

    useEffect(() => {
        if (props.messages && props.messages.messages) {
            setMessages(props.messages.messages)
            setTimeout(() => messagesEndRef.current?.scrollIntoView({ block: "end" }), 0);
        }
    }, [props.messages])

    return (
        <>
            <div className="chat-history">
                {messages ? <>
                    <ul className="mb-0" dangerouslySetInnerHTML={{ __html: messages }}></ul>
                    <div ref={messagesEndRef} />
                </> : <>
                    <div className="text-center">
                        <img src={"assets/images/chat.jpg"} alt="chat" height={"400px"} />
                    </div>
                </>}
            </div>
        </>
    )
}

export default memo(Messages)

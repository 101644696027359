import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"
import { AuthSection } from "../../components/Layouts/Layout";
import Tiles from "./Components/Tiles";
import { fetchData } from "../../components/Helpers/Helper";
import { COMPANY_DASHBOARD, DASHBOARD } from "../../components/Helpers/ApiRoutes";
import { isCandidate, isCompany } from "../../components/Helpers/RoleHelper";

const Dashboard = () => {
    const { t } = useTranslation()
    const [data, setData] = useState({});

    useEffect(() => {
        fetchData(isCandidate ? DASHBOARD : COMPANY_DASHBOARD, 'GET', '', true, true, (res) => {
            if (res.status) {
                setData(res.body)
            }
        });
    }, [])



    return (
        <AuthSection breadCrumbs={<span className="breadcrumb-item active">{t("nav.dashboard")}</span>}>
            <div className="row clearfix row-deck">
                {isCandidate && <>
                    <Tiles title={t("nav.applied_jobs")} icon="fa fa-building" count={data.appliedJobs ?? 0} navigate={'/applied-jobs'} />
                    <Tiles title={t("nav.favourite_job")} icon="fa fa-heart" count={data.favoriteJobs ?? 0} navigate={'/favourite-job'} />
                    <Tiles title={t("nav.notifications")} icon="fa fa-bell" count={data.notifications ?? 0} navigate={'/notifications'} />
                    <Tiles title={t("nav.visitor_log")} icon="fa fa-users" count={data.allVisitor ?? 0} navigate={'/visitor-log'} />
                </>}
                {isCompany && <>
                    <Tiles title={t("open_job_count")} icon="fa fa-building" count={data.openJobCount ?? 0} navigate={'/my-jobs'} />
                    <Tiles title={t("pending_job_count")} icon="fa fa-heart" count={data.pendingJobCount ?? 0} navigate={'/my-jobs'} />
                    <Tiles title={t("saved_candidate")} icon="fas fa-id-card-alt" count={data.savedCandidates ?? 0} navigate={'/bookmark-candidate'} />
                    <Tiles title={t("nav.visitor_log")} icon="fa fa-users" count={data.visiterCount ?? 0} navigate={'/visitor-log'} />
                    <h3>Remaining features on current plan</h3>
                    {data.userplan && <>
                        <Tiles title={t("active_jobs_limit")} icon="fa fa-suitcase" count={data.userplan.job_limit ?? 0} />
                        <Tiles title={t("highlight_jobs_limit")} icon="fa fa-shield" count={data.userplan.highlight_job_limit ?? 0} />
                        <Tiles title={t("featured_jobs_limit")} icon="fas fa-check-circle" count={data.userplan.featured_job_limit ?? 0} />
                        <Tiles title={t("profile_view_limit")} icon="fa fa-users" count={data.userplan.candidate_cv_view_limit ?? 0} />
                    </>}
                </>}
            </div>
        </AuthSection>
    )
}

export default Dashboard
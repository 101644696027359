import { useState } from "react";
import { useTranslation } from "react-i18next"
import Select from 'react-select'

const SearchHeader = (props) => {
    const { t } = useTranslation();
    const [query, setQuery] = useState({ profession: '', country: '', keyword: '', gender: '' });

    return (
        <>
            <div className="search-menu">
                <div className="search-bar">
                    <Select className="jobFilterSelect" isClearable name="professions" options={props.data && props.data.professions} placeholder={t("professions")} onChange={(e) => setQuery(prev => ({ ...prev, profession: e ? e.label : '' }))} />
                </div>
                <div className="search-bar">
                    <Select className="jobFilterSelect" isClearable name="gender" options={[{ value: "male", label: "Male" }, { value: "female", label: "Female" }]} placeholder={t("form.gender")} onChange={(e) => setQuery(prev => ({ ...prev, gender: e ? e.label : '' }))} />
                </div>
                <div className="search-location">
                    <i className="fa fa-map me-2"></i>
                    <input type="text" placeholder={t("country")} className="search-box" value={query.country} onChange={(e) => setQuery(prev => ({ ...prev, country: e.target.value }))} />
                </div>
                <div className="search-salary">
                    <i className="fa fa-building me-2"></i>
                    <input type="text" placeholder={t("Job_keyword")} className="search-box" value={query.keyword} onChange={(e) => setQuery(prev => ({ ...prev, keyword: e.target.value }))} />
                </div>
                <button className="search-button" onClick={() => props.setQuery(query)}>{t("find_a_candidate")}</button>
            </div>
        </>
    )
}

export default SearchHeader
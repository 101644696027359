// Settings Routes
export const GET_SITE_DATA = "get-site-data"

// Auth Routes
export const REGISTER = "register"
export const LOGIN = "login"
export const LOGOUT = "logout"
export const FORGOT_PASSWORD = "forgot-password"
export const RESET_PASSWORD = "reset-password"
export const VERIFY_EMAIL = "verify-email"
export const GET_PROFILE = "get-my-profile"
export const UPDATE_PROFILE = "profile/update"
export const UPDATE_CV = "profile/cv-parser"
export const RESUME_VIDEO_UPLOAD = "profile/upload-resume-video"
export const DOWNLOAD_CV = "download-candidate-resume"
export const DELETE_RESUME = "profile/delete-resume"
export const UPDATE_PROFILE_IMAGE = "profile/update-avatar"
export const UPDATE_CONTACT_INFO = "profile/update-contact-info"
export const UPDATE_SOCIAL_LINK = "profile/update-social-links"
export const UPDATE_JOB_ALERT = "profile/receive-job-alert"
export const GET_SOCIAL_LINK = "profile/get-social-links"
export const GET_PERSONAL_INFO = "profile/get-personal-info"
export const UPDATE_PERSONAL_INFO = "profile/update-personal-info"

export const CHANGE_PASSWORD = "change-password"
export const GET_AUTH_USER = "profile"

// Chat Auth Routes
export const CHAT_AUTH = "chat/auth"
export const FETCH_MESSAGES = "fetchMessages"

export const CHATIFY = {
    auth: "chat/auth",
    fetchMessages: "fetchMessages",
    sendMessage: "sendMessage",
    getContacts: "getContacts",
    makeSeen: "makeSeen",
    setActiveStatus: "setActiveStatus",
    deleteConversation: "deleteConversation",
    search: "search",
}

// CMS (Pages) Routes
export const HOME = "home"
export const BLOG = "posts"
export const BLOG_DETAILS = "post"
export const ALL_CATEGORY="job-category"
export const GET_MAIL = "get-mails"
export const COMPOSE_MAIL = "compose-mail"
export const REPLY_MAIL = "mailReply"
export const BOOKMARK = "bookmark-mail"
export const SEARCH_MAIL = "search-mail"
export const GET_SERVER_MAIL = "get-all-mails"

// Dashboard Routes
export const DASHBOARD = "profile/dashboard"
export const APPLIED_JOBS = "profile/applied-jobs"
export const JOB_ALERT = "profile/job-alerts"
export const FAVOURITE_JOBS = "candidate/favourite-jobs"
export const NOTIFICATIONS = "profile/notifications"
export const UNREAD_NOTIFICATIONS = "profile/notifications/unread"
export const READ_NOTIFICATIONS = "profile/notifications/read"
export const VISITOR_LOG = "profile/visitor-logs"
export const GET_JOBS = "get-jobs"
export const GET_JOB_DETAILS = "get-jobs-details"
export const GET_COMPANY_DETAILS = "company-details"
export const GET_JOB_FILTER = "get-jobs-filter"
export const Setting = "profile/Setting"
export const PLAN = "plans"
export const GET_JOB_ROLE = "get-jobs-role"
export const CANDIDATES = "candidates"
export const CANDIDATES_FILTER = "candidate-filter"
export const CANDIDATES_DETAILS = "candidate-details"

// Company Routes


export const COMPANY_DASHBOARD = "company/dashboard"
export const BUSINESS_LIST = "companies" 
export const CREATE_JOB_POST = "company/create-job-post" 
export const COMPANY_PROFILE_UPDATE = "company/profile-update" 

export const POST_JOB = "company/posted-jobs" 
export const DROP_DOWN =  "company/fetch-all-dropdown-data"
export const CANDIDATES_LIST = "company/bookmarked-candidates"
export const BOOKMARK_CANDIDATES_LIST = "company/bookmarked-candidates"
export const BOOKMARK_LIST = "company/list-bookmark-category"
export const CREATE_BOOKMARK = "company/create-bookmark"
export const UPDATE_BOOKMARK = "company/create-update-bookmark-category"
export const DELETE_BOOKMARK = "company/delete-bookmark-category"
export const EXPIRE_JOB = "company/make-job-post-expired"
export const CANDIDATE_BOOKMARK = "company/bookmark-candidate"
export const COMPANY_NOTIFICATION = "company/notifications"
export const BOOKMARK_CANDIDATE_CATEGORY = "company/fetch-dropdown-category"
import { now } from "lodash";
import TimeAgo from 'javascript-time-ago'
import { CHATIFY, GET_JOB_DETAILS } from "../../../components/Helpers/ApiRoutes";
import { chatifyfetcher, fetchData } from "../../../components/Helpers/Helper";
import en from 'javascript-time-ago/locale/en'
TimeAgo.addDefaultLocale(en)

export const pusherChannel = "private-chatify";

export const pusherCredential = (token) => {
    let credential = {
        cluster: process.env.REACT_APP_CLUSTER,
        wsHost: process.env.REACT_APP_WS_HOST,
        wsPort: process.env.REACT_APP_WS_PORT,
        wssPort: process.env.REACT_APP_WSS_PORT,
        encrypted: true,
        forceTLS: false,
        authEndpoint: process.env.REACT_APP_CHATFY_URL + CHATIFY.auth,
        auth: {
            headers: {
                "Authorization": "Bearer " + token,
            },
        }
    }

    return credential;
}

export const pusherSubscription = (pusher, setMember) => {
    chatifyfetcher(CHATIFY.setActiveStatus, "POST", { status: 1 }, true, false, (res) => { });
    let activeStatusChannel = pusher.subscribe(`presence-activeStatus`);

    activeStatusChannel.bind('pusher:subscription_succeeded', (members) => members.each((key) => setMember(prevState => ([...prevState, key]))));

    activeStatusChannel.bind('pusher:member_removed', (member) => {
        document.querySelector(`#plist ul li[data-contact="${member.id}"] div.about div.status i`).remove();
        document.querySelector(`#plist ul li[data-contact="${member.id}"] div.about div.status`).innerHTML = '<i class="fa fa-circle offline"></i> Offline';
        setMember(prevState => prevState.filter((item) => `${item.id}` !== `${member.id}`))
    });

    activeStatusChannel.bind('pusher:member_added', (member) => {
        document.querySelector(`#plist ul li[data-contact="${member.id}"] div.about div.status i`).remove();
        document.querySelector(`#plist ul li[data-contact="${member.id}"] div.about div.status`).innerHTML = '<i class="fa fa-circle online"></i> Online';
        setMember(prevState => ([...prevState, member]))
    });
}

export const pusherDisconnect = (pusher, auth_id) => {
    if (pusher) {
        chatifyfetcher('setActiveStatus', 'POST', { status: 0 }, true, false, (res) => { })
        pusher.disconnect();
        pusher.unsubscribe(`presence-activeStatus`);
        pusher.unsubscribe(`${pusherChannel}.${auth_id}`);
        pusher.disconnect();
    }
}

export const fetchAllContact = (setContactList) => {
    chatifyfetcher(`${CHATIFY.getContacts}`, 'GET', '', true, false, (res) => {
        if (res.total > 0 && res.contacts) {
            setContactList(res.contacts)
        }
    });
}

export const setReceiverAndRecentReceiverId = (setRecieverId, setRecentReciever) => {
    function getData(element, setRecieverId) {
        document.querySelectorAll("ul.chat-list li").forEach(element => element.classList.remove("active"));
        element.classList.add("active");
        setRecieverId((prevId) => {
            setRecentReciever(prevId)
            return element.dataset.contact
        })
    }
    document.querySelectorAll("ul.chat-list li").forEach(element => element.removeEventListener("click", () => getData(element, setRecieverId)));
    document.querySelectorAll("ul.chat-list li").forEach(element => element.addEventListener("click", () => getData(element, setRecieverId)));
}

export const fetchMessageAndUserDetails = (recieverId, setMessageList, setReciever, setSeen) => {
    setMessageList(prev => { });
    chatifyfetcher('idInfo', 'POST', { id: recieverId }, true, false, (res) => setReciever(res))
    chatifyfetcher(CHATIFY.fetchMessages, 'POST', { id: recieverId }, true, false, (res) => setMessageList(prev => res));
    setSeen(now());
}

export const listenMessagingEvent = (clientListenChannel, setMessageList, senderId, receiverId, setSeen) => {
    clientListenChannel.bind("messaging", function (data) {
        if (`${data.from_id}` === `${receiverId}` && `${data.to_id}` === `${senderId}`) {
            setMessageList((prev) => ({ ...prev, messages: prev.messages + data.message }));
            chatifyfetcher(CHATIFY.makeSeen, 'POST', { id: receiverId }, true, false, (res) => { })
            setTimeout(() => setSeen(now()), 1000);
        }
    });
}

export const listenTypingEvent = (clientListenChannel, setMemberActivity) => {
    clientListenChannel.bind("client-typing", function (data) {
        setMemberActivity((prevState) => {
            let exist = prevState.filter((item) => `${data.from_id}` === `${item.from_id}` && `${data.to_id}` === `${item.to_id}`);
            if (exist && exist.length > 0) return prevState.map((item) => `${data.from_id}` === `${item.from_id}` && `${data.to_id}` === `${item.to_id}` ? { ...item, ...data, typing: data.typing } : item);
            else return [...prevState, { ...data, typing: data.typing }];
        });
    });
}

export const listenSeenEvent = (clientListenChannel) => {
    clientListenChannel.bind("client-seen", function (data) {
        document.querySelectorAll(".chat-history ul li .check-double").forEach(element => {
            element.innerHTML = '<i class="fa-solid fa-check-double"></i>';
        });
    });
}

export const whenMessageChange = (user_id, setJob) => {
    function getJobDetails(slug) {
        fetchData(`${GET_JOB_DETAILS}/${slug}?user_id=${user_id}`, "GET", "", false, true, (res) => {
            if (res.status) {
                setJob(res.body.job);
            }
        });
    }

    document.querySelectorAll(".chat ul li button.job-detail-pop").forEach((element) => {
        if (element.dataset.slug) {
            element.removeEventListener("click", () => getJobDetails(element.dataset.slug));
            element.addEventListener("click", () => getJobDetails(element.dataset.slug));
        }
    });

    document.querySelectorAll(".chat .chat-history ul li .message-data-time").forEach((element) => {
        if (element.dataset.time) {
            setInterval(() => {
                let timeAgo = new TimeAgo('en-US');
                let diff_seconds = now() - new Date(element.dataset.time).getTime();
                let diff_formatted = timeAgo.format(now() - diff_seconds);
                element.innerHTML = diff_formatted;
            }, 6000);
        }
    });
}

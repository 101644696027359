import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { useEffect, useState } from 'react';

export const DraftEditor = (props) => {
    const [data, setData] = useState(EditorState.createEmpty());

    useEffect(() => {
        if (props.data) {
            let text = EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(props.data)));
            setData(text);
        }
    }, [props.data])

    return (<>
        <textarea className="d-none" name={props.name} readOnly value={data && draftToHtml(convertToRaw(data.getCurrentContent()))} />
        <Editor editorStyle={{height: props.heigth ?? "400px", padding: "0px 10px"}} editorState={data} wrapperClassName="demo-wrapper" editorClassName="demo-editor border" onEditorStateChange={(data) => setData(data)} />
    </>)
}
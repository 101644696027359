import "./home.scss"
import BannerSection from "./Components/BannerSection"
import CategoriesSection from "./Components/CategoriesSection"
import CompanySection from "./Components/CompanySection"
import TestimonialSection from "./Components/TestimonialSection"
import { useEffect, useState } from "react"
import { fetchData } from "../../components/Helpers/Helper"
import { HOME } from "../../components/Helpers/ApiRoutes"

const Home = () => {
    const [data, setData] = useState({});

    useEffect(() => {
        fetchData(HOME, 'GET', '', false, true, (res) => {
            if (res.status) {
                setData(res.body)
            }
        })
    }, [])

    return (
        <div className="home-section">
            <BannerSection data={data.top_categories} roles={data.job_roles} />
            <CategoriesSection data={data.popular_categories} />
            <CompanySection data={data.top_companies} />
            <TestimonialSection data={data.testimonials} />
        </div>
    )
}

export default Home
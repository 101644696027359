import "./about.scss"
import { useTranslation } from "react-i18next"
import { noImageHandle } from "../../components/Helpers/Helper"
import Banner from "../../components/Partials/Banner"

const About = () => {
    const { t } = useTranslation();

    return (
        <div className="about-section">
            <Banner title="About Us" />
            <section className="about-section ptb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-text">
                                <div className="section-title">
                                    <h2 className="m-0">{(t("how_we_started"))}</h2>
                                </div>
                                <p>{t("it_is_a_long_established_fact")}</p>
                                <p>{t("top_category_short_description")}</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-img">
                                <img src="/img/about-image.jpg" alt="about" onError={noImageHandle} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default About
import { useState } from "react";
import { useTranslation } from "react-i18next";
import hi from "./hi.json";
import en from "./en.json";
import ar from "./ar.json";

const languages = [{ name: 'English', code: 'en', dir: "ltr" }, { name: 'Arabic', code: 'ar', dir: "rtl" }];
// const languages = [{ name: 'Hindi', code: 'hi', dir: "ltr" }, { name: 'English', code: 'en', dir: "ltr" }, { name: 'Arabic', code: 'ar', dir: "rtl" }];

export const currentLangMode = () => languages.filter(lang => lang.code === (localStorage.getItem('localization') ?? 'en'))[0];

export const tableTrans = (key) => {
    let lang = localStorage.getItem('localization') ?? 'en'

    if (lang === 'en') return en.table[key] ?? key
    else if (lang === 'hi') return hi.table[key] ?? key
    else if (lang === 'ar') return ar.table[key] ?? key
    else return key
}

export const setCurrentLanguage = (lang_code = (localStorage.getItem('localization') ?? 'en')) => {
    let { code, dir } = languages.filter(lang => lang.code === lang_code)[0];
    document.documentElement.dir = dir;
    document.documentElement.lang = code;
    if (dir === "rtl") document.querySelector("body").classList.add("rtl_mode")
    else document.querySelector("body").classList.remove("rtl_mode")
}

function ChangeLanguage() {
    const [language, setLanguage] = useState(localStorage.getItem('localization') ?? 'en');
    const { i18n } = useTranslation();

    const changeLanguageHandler = (lang_code) => {
        setCurrentLanguage(lang_code)
        setLanguage(lang_code)
        i18n.changeLanguage(lang_code);
        localStorage.setItem('localization', lang_code);
    }
    return (
        <div className="signup-btn">
            <select className="form-select languageSelect" aria-label="Default select example" value={language} onChange={(e) => changeLanguageHandler(e.target.value)}>
                {languages.map(lang => <option key={lang.code} value={lang.code}>{lang.name} ({lang.code})</option>)}
            </select>
        </div>
    );
}

export default ChangeLanguage;
import { AuthSection } from "../../components/Layouts/Layout";
import { useTranslation } from "react-i18next"
import { JOB_ALERT } from "../../components/Helpers/ApiRoutes";
import NotificationList from "../../components/Partials/NotificationList";

const JobAlert = () => {
    const { t } = useTranslation();

    return (
        <AuthSection breadCrumbs={<span className="breadcrumb-item active">{t("nav.job_alert")}</span>}>
            <NotificationList listUrl={JOB_ALERT} />
        </AuthSection>
    )
}

export default JobAlert
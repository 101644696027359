import { VISITOR_LOG } from "../../components/Helpers/ApiRoutes";
import { useTranslation } from "react-i18next"
import { AuthSection } from "../../components/Layouts/Layout";
import VisitorLogList from "../../components/Partials/VisitorLogList";

const VisitorLog = () => {
    const { t } = useTranslation();

    return (
        <AuthSection breadCrumbs={<span className="breadcrumb-item active">{t("nav.visitor_log")}</span>}>
            <VisitorLogList listUrl={VISITOR_LOG} />
        </AuthSection>
    )
}

export default VisitorLog
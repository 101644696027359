import "../job-details.scss"
import { Link } from "react-router-dom"
import { fetchData, noImageHandle } from "../../../components/Helpers/Helper"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Context from "../../../components/Helpers/Context"
import { CANDIDATES_DETAILS } from "../../../components/Helpers/ApiRoutes"
import Banner from "../../../components/Partials/Banner"
import { upperFirst } from "lodash"

const CandidateDetail = (props) => {
    const [context] = useContext(Context);
    const { t } = useTranslation();
    const [data, setData] = useState({});
    const [candidate, setCandidate] = useState();

    useEffect(() => {
        if (props && props.data) {
            fetchData(`${CANDIDATES_DETAILS}/${props.data}`, 'GET', '', false, true, (res) => {
                if (res.status) {
                    setData(res.body)
                    setCandidate(res.body.candidate)
                }
            })
        }
    }, [props, context])
    return (
        <>
            {props.banner ? <Banner title="Employee Details" /> : ''}
            <section className="job-details ptb-100 job-section job-details-section">
                <div className="container">
                    {candidate && data && <>
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="job-details-text">
                                            <div className="job-card">
                                                <div className="row align-items-center">
                                                    <div className="col-md-2">
                                                        <div className="company-logo">
                                                            <Link className="manage_icon" to="/company-details" state={{ name: data.name }}>
                                                                <img src={candidate.photo ? candidate.photo : ""} alt="logo" onError={noImageHandle} />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <div className="job-info">
                                                            <h3>{data.name}</h3>
                                                            <ul>
                                                                <li>
                                                                    <i className="fa fa-location"></i>
                                                                    {data.email}
                                                                    <i className="bx bx-location-plus"></i>
                                                                </li>
                                                                <li>
                                                                    <i className="fa fa-location"></i>
                                                                    {candidate.full_address}
                                                                    <i className="bx bx-location-plus"></i>
                                                                </li>
                                                                <li>
                                                                    <i className="fa fa-filter"></i>
                                                                    {candidate.job_role ? `${candidate.job_role.name}` : ' NA'}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="details-text mt-3">
                                                <h3>{t("Bio")}</h3>
                                                <div dangerouslySetInnerHTML={{ __html: candidate.bio }}></div>
                                            </div>
                                            {candidate.resumes_video && candidate.resumes_video.length > 0 && <>
                                                <div className="details-text mt-3">
                                                    <div className="embed-responsive embed-responsive-16by9">
                                                        <iframe title="map" className="embed-responsive-item" src={candidate.resumes_video.length[0].file} allowfullscreen></iframe>
                                                    </div>
                                                </div>
                                            </>}
                                            <div className="details-text">
                                                <h3>Employee Details</h3>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td><span>Job Role</span></td>
                                                                    <td>{candidate.job_role && candidate.job_role.name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span>Phone</span></td>
                                                                    <td>{data.contact_info && data.contact_info.phone}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span>Location</span></td>
                                                                    <td>{candidate.full_address}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td><span>Experince</span></td>
                                                                    <td>{candidate.experience && candidate.experience.name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span>Gender</span></td>
                                                                    <td>{upperFirst(candidate.gender)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span>Education</span></td>
                                                                    <td>{candidate.education && candidate.education.name}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 ">
                                <div className="job-sidebar">
                                    <h3>Location</h3>
                                    <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387190.27991517034!2d-74.25987556253516!3d40.697670063539654!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1588772651198!5m2!1sen!2sbd"></iframe>
                                </div>
                                {data && data.social_info && data.social_info.length > 0 && <>
                                    <div className="job-sidebar social-share">
                                        <h3>{t("share_in")}</h3>
                                        <ul>
                                            {data.social_info.map((si, key) => {
                                                return (
                                                    <li key={key}>
                                                        <Link to={si.url} target="_blank">
                                                            <i className={`bx bxl-${si.social_media}`}></i>
                                                        </Link>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </>}
                            </div>
                        </div>
                    </>}
                </div>
            </section>
        </>
    )
}

export default CandidateDetail
import { useTranslation } from "react-i18next"
import Category from "../../../components/Partials/Category";
import { Link } from "react-router-dom";


const CategoriesSection = ({ data }) => {
    const { t } = useTranslation();

    return (
        <>
            {data && data.length > 0 &&
                <section className="categories-section pt-100">
                    <div className="container">
                        <div className="section-title text-center">
                            <h2>{t("choose_your_category")}</h2>
                            <p style={{ color: "#081c3c", textAlign: "center" }}>{t("top_category_short_description")}</p>
                        </div>
                        <div className="row">
                            {data.map((category, key) => <Category key={key} title={category.name} icon={category.icon} disc={`${category.open_jobs_count} ${t('open position')}`} />)}
                             <div className="more_data">
                                <Link className="find-btn" to="/allcategory">See More</Link>
                             </div>
                        </div>
                    </div>
                </section>
            }
        </>
    )
}

export default CategoriesSection
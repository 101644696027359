
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react";
import { BLOG } from "../../../components/Helpers/ApiRoutes";
import { fetchData } from "../../../components/Helpers/Helper";
import { accordian } from "./constant";
import { data } from "jquery";



const useFaqFunction = () => {
    const { t } = useTranslation();
    const [data, setData] = useState({});
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        fetchData(`${BLOG}?page=${currentPage}`, 'GET', '', false, true, (res) => {
            if (res.status) {
                setData(res.body)
            }
        })
    }, [currentPage])
     
  return {
    accordian,data, setData,t,currentPage, setCurrentPage
  };
}
export default useFaqFunction


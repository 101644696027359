import "./employee.scss"
import { useTranslation } from "react-i18next"
import SearchHeader from "./Components/SearchHeader"
import SideFilter from "./Components/SideFilter"
import JobItem from "./Components/JobItem"
import { useContext, useEffect, useState } from "react"
import { fetchData } from "../../components/Helpers/Helper"
import { GET_JOBS, GET_JOB_FILTER } from "../../components/Helpers/ApiRoutes"
import Pagination from "../../components/Helpers/Pagination"
import _, { now } from "lodash"
import Context from "../../components/Helpers/Context"

const Employee = () => {
    const { t } = useTranslation();
    const [data, setData] = useState({});
    const [filter, setFilter] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [category, setCategory] = useState(localStorage.getItem('job_filter') && JSON.parse(localStorage.getItem('job_filter')).job_categories ? [_.toString(JSON.parse(localStorage.getItem('job_filter')).job_categories)] : []);
    const [education, setEducation] = useState([]);
    const [experience, setExperience] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [query, setQuery] = useState({ job_role: '', country: '', keyword: '', job_type: '', sort_by: '' });
    const [context] = useContext(Context);

    useEffect(() => {
        fetchData(GET_JOB_FILTER, 'GET', '', false, true, (res) => {
            if (res.status) {
                setFilter(res.body)
            }
        })
    }, [currentPage])

    useEffect(() => {
        let api_url = GET_JOBS;
        if (localStorage.getItem('job_filter')) {
            let job_filter = JSON.parse(localStorage.getItem('job_filter'));
            api_url += `?category=${category}&job_role=${job_filter.job_roles && job_filter.job_roles.label ? job_filter.job_roles.label : ''}&country=${job_filter.location ?? ''}&keyword=${job_filter.keyword ?? ''}`;
        } else {
            api_url += `?experience=${experience}&education=${education}&category=${category}&sort_by=${query.sort_by}&job_type=${query.job_type}&job_role=${query.job_role}&country=${query.country}&keyword=${query.keyword}`;
        }
        if (context && context.auth && context.auth.user_details) {
            api_url += `&user_id=${context.auth.user_details.id}`;
        }

        const controller = new AbortController();
        const signal = controller.signal;
        fetchData(api_url, 'GET', '', false, true, (res) => {
            if (res.status) {
                localStorage.removeItem('job_filter')
                setData(res.body)
            }
        }, signal)
        return () => controller.abort()
    }, [currentPage, experience, education, category, query, refresh, context])

    return (
        <section className="job-section">
            <div className="job">
                <div className="job-wrapper">
                    <SearchHeader setQuery={setQuery} data={filter} />
                    <div className="main-container">
                        <div className="search-type">
                            <SideFilter refresh={() => setRefresh(now())} query={experience} setQuery={setExperience} title="Experience" data={filter && filter.experiences} />
                            <SideFilter refresh={() => setRefresh(now())} query={education} setQuery={setEducation} title="Educations" data={filter && filter.educations} />
                            <SideFilter refresh={() => setRefresh(now())} query={category} setQuery={setCategory} title="Categories" data={filter && filter.categories} />
                        </div>
                        <div className="searched-jobs">
                            <div className="searched-bar mb-3">
                                <div className="searched-show">Showing {data.total ?? 0} Jobs</div>
                                <select className="form-select" aria-label="Default select example" onChange={(e) => setQuery(prev => ({ ...prev, sort_by: e.target.value }))}>
                                    <option value="Recommended">{t("Recommended")}</option>
                                    <option value="latest">{t("latest")}</option>
                                    <option value="featured">{t("featured")}</option>
                                </select>
                            </div>
                            {data.data && <>
                                {data.data.length > 0 ? data.data.map((item, key) => <JobItem key={key} data={item} />) : <>
                                    <div className="find-search-image  w3-animate-bottom l">
                                    <img src="img/card2.jpg" class="img-fluid" alt=""/>
                                    </div>
                                </>}
                            </>}
                            <div className="row">
                                <div className="col-sm-12">
                                    <Pagination paginate_data={data} onPageChange={(page) => setCurrentPage(page.selected >= 0 ? page.selected + 1 : 0)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Employee
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'intl-tel-input/build/css/intlTelInput.css';
import "react-datepicker/dist/react-datepicker.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './components/fonts/flaticon/flaticon.css';
import './components/Css/boxicon.min.css';
import './components/Css/boxicon.min.css';
import './components/Css/dashboard.scss';
import './components/Css/index.scss';
import './components/Css/rtl-mode.scss';
import './i18n.js';
import Routing from './components/Routing';
import reportWebVitals from './reportWebVitals';
import { setCurrentLanguage } from './components/Lang/ChangeLanguage';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

setCurrentLanguage()

root.render(
    <>
        <Router>
            <Routing />
        </Router>
        <div className="position-fixed top-0 end-0 p-2 toast-end pointer-none" style={{ 'zIndex': '1111' }} id="toastContainer"></div>
    </>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

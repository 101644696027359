import { useTranslation } from "react-i18next"
import { noImageHandle } from "../../../components/Helpers/Helper"
import { Navigation, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.css';

const CompanySection = ({ data }) => {
    const { t } = useTranslation();
    const params = {
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: {
          delay: 800,
          disableOnInteraction: false
        },
        loop:true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
      }
    return (
        <>
            {data && data.length > 0 &&
                <section className="company-location pt-100 pb-70">
                    <div className="container">
                        <div className="section-title text-center">
                            <h2>{t("top_companies")}</h2>
                            <p>{t("top_company_short_description")}</p>
                        </div>
                        <div className="ourSlider-section">
                            <div className="swiper mySwiper">
                                <Swiper{...params}  modules={[Navigation, Pagination, Autoplay]} breakpoints={{

592: {
  width: 592,
  slidesPerView: 1,
},

768: {
  width: 768,
  slidesPerView: 2,
},
992:{
    width: 992,
  slidesPerView: 3,
},
1200:{
    width: 1200,
    slidesPerView: 3,
}
}}>
                                    {data.map((item, key) => {
                                        return (
                                            <SwiperSlide key={key}>
                                                <img src={item.banner_url} alt="" onError={noImageHandle} />
                                            </SwiperSlide>
                                        )
                                    })}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </section >
            }
        </>
    )
}

export default CompanySection
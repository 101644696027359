const Category = (props) => {
    return (
        <>
            <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="category-card d-flex justify-content-center align-items-center">
                        <div className="px-2">
                        <i className={props.icon}></i>
                        <h3>{props.title}</h3>
                        <p className="text-center">{props.disc}</p>
                        </div>
                    </div>
            </div>
        </>
    )
}

export default Category
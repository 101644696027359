import React, { useEffect, useState } from 'react';
import { Route, Routes } from "react-router-dom";

import Context from './Helpers/Context';
import { fetchData } from './Helpers/Helper';
import Layout, { AuthLayout } from './Layouts/Layout';
import ManagePage from './Layouts/ManagePage';

import Home from '../modules/Home/Home';
import Dashboard from '../modules/Dashboard/Dashboard';
import { GET_AUTH_USER } from './Helpers/ApiRoutes';
import About from '../modules/About/About';
import Contact from '../modules/Contact/Contact';
import Blog from '../modules/Blog/Blog';
import Job from '../modules/Job/job';
import Candidate from '../modules/Candidate/Candidate';
import SignIn from '../modules/Auth/SignIn';
import ChangePassword from '../modules/Auth/ChangePassword';
import AppliedJob from '../modules/AppliedJob/AppliedJob';
import MyJobs from '../modules/MyJobs/MyJobs';
import CreateJob from '../modules/CreateJob/CreateJob';
import JobDetails from '../modules/Job/JobDetails';
import CandidateDetails from '../modules/Candidate/CandidateDetails';
import CompanyDetails from '../modules/CompanyDetails/companyDetails';
import Chat from '../modules/Chat/Chat';
import VerifyEmail from '../modules/Auth/VerifyEmail';
import Setting from '../modules/Setting/Setting';
import FavouriteJob from '../modules/FavouriteJob/FavouriteJob';
import ForgotAccount from '../modules/Auth/ForgotAccount';
import JobAlert from '../modules/JobAlert/JobAlert';
import Notification from '../modules/Notification/Notification';
import Email from '../modules/Email/Email';
import Pricing from '../modules/pricing/Pricing';
import BlogDetails from '../modules/Blog/BlogDetails';
import { isCandidate, isCompany } from './Helpers/RoleHelper';
import VisitorLog from '../modules/VisitorLog/VisitorLog';
import PricingDetails from '../modules/pricing/PricingDetails';
import Employee from '../modules/Employee/Employee';
import AllCategory from '../modules/Home/Components/AllCategory';
import Policy from '../modules/policy/privacyPolicy';
import Faq from '../modules/Faq/Faq';
import BookmarkCandidate from '../modules/BookmarkCandidate/BookmarkCandidate';
import ChatWithJs from '../modules/ChatWithJs/ChatWithJs';

export default function Routing() {
    const [context, setContext] = useState();
    const [token] = useState(localStorage.getItem('recruitment-token'));
    useEffect(() => {
        if (token) {
            fetchData(GET_AUTH_USER, 'GET', '', true, false, (res) => {
                if (res.status) {
                    setContext(prevState => ({ ...prevState, auth: res.body }))
                }
            })
        }

    }, [token]);

    return (
        <>
            <Context.Provider value={[context, setContext]}>
                <Routes>
                    {/* Normal Pages routes */}
                    <Route path="/" exact caseSensitive={false} element={<Layout />}>
                        <Route path="/" caseSensitive={false} element={<Home />} />
                        <Route path="/home" caseSensitive={false} element={<Home />} />
                        <Route path="/allcategory" caseSensitive={false} element={<AllCategory />} />
                        <Route path="/about-us" caseSensitive={false} element={<About />} />
                        <Route path="/contact-us" caseSensitive={false} element={<Contact />} />
                        <Route path="/privacy-policy" caseSensitive={false} element={<Policy />} />
                        <Route path="/faq" caseSensitive={false} element={<Faq />} />
                        <Route path="/blog" caseSensitive={false} element={<Blog />} />
                        <Route path="/faq" caseSensitive={false} element={<Faq />} />
                    
                        <Route path="/blog-details" caseSensitive={false} element={<BlogDetails />} />
                        <Route path="/Job-details" caseSensitive={false} element={<JobDetails />} />
                        <Route path="/candidate-details" caseSensitive={false} element={<CandidateDetails />} />
                        <Route path="/jobs" caseSensitive={false} element={<Job />} />
                        <Route path="/candidate" caseSensitive={false} element={<Candidate />} />
                        <Route path='/company-details' caseSensitive={false} element={<CompanyDetails />} />
                        <Route path="/pricing" caseSensitive={false} element={<Pricing />} />
                        <Route path="/pricing-details" caseSensitive={false} element={<PricingDetails />} />
                        <Route path="/employee" caseSensitive={false} element={<Employee />} />
                    </Route>

                    {/* Unauthorized routes */}
                    <Route path="/" exact caseSensitive={false} element={<AuthLayout />}>
                        <Route path="sign-in" caseSensitive={false} element={<SignIn />} />
                        <Route path="verify-email" caseSensitive={false} element={<VerifyEmail />} />
                        <Route path="forgot-password" caseSensitive={false} element={<ForgotAccount />} />
                        <Route path="reset-password" caseSensitive={false} element={<ForgotAccount />} />
                    </Route>
                    {/* Authorized routes */}
                    <Route path="/" exact caseSensitive={false} element={<AuthLayout authorized />}>
                        <Route path="dashboard" caseSensitive={false} element={<Dashboard />} />
                        <Route path="change-password" caseSensitive={false} element={<ChangePassword />} />
                        <Route path="chat" caseSensitive={false} element={<Chat />} />
                        <Route path="chat-js" caseSensitive={false} element={<ChatWithJs />} />
                        <Route path="setting" caseSensitive={false} element={<Setting />} />
                        <Route path="notifications" caseSensitive={false} element={<Notification />} />
                        <Route path="visitor-log" caseSensitive={false} element={<VisitorLog />} />
                        {isCandidate && <>
                            <Route path="applied-jobs" caseSensitive={false} element={<AppliedJob />} />
                            <Route path="favourite-job" caseSensitive={false} element={<FavouriteJob />} />
                            <Route path="job-alert" caseSensitive={false} element={<JobAlert />} />
                        </>}

                        {isCompany && <>
                            <Route path="create-jobs" caseSensitive={false} element={<CreateJob />} />
                            <Route path="email" caseSensitive={false} element={<Email />} />
                            <Route path="my-jobs" caseSensitive={false} element={<MyJobs />} />
                            <Route path="bookmark-candidate" caseSensitive={false} element={<BookmarkCandidate />} />
                        </>}
                    </Route>

                    <Route path="/*" caseSensitive={false} element={<ManagePage code={404} />} />
                </Routes>
            </Context.Provider>
        </>
    )
}


import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom";

const ContactInfo = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="col-lg-4 col-md-5 mt-4 pt-2">
                <div className="border rounded text-center p-4">
                    <h5 className="text-dark pb-3">{t("contact_info")}</h5>
                    <div className="contact-location rounded mt-5 p-4">
                        <div className="contact-location-icon bg-white text-primary rounded-pill">
                            <i className="bx bx-map"></i>
                        </div>
                        <p className="text-muted pt-4 f-20 mb-0">{t("site.address")}</p>
                    </div>
                    <h6 className="text-muted mt-4 mb-0">{t("share")}</h6>
                    <ul className="list-unstyled social-icon mt-3 mb-0">
                        <li className="list-inline-item"><Link href="#" className=""><i className="bx bxl-facebook"></i></Link></li>
                        <li className="list-inline-item"><Link href="#" className=""><i className="bx bxl-twitter"></i></Link></li>
                        <li className="list-inline-item"><Link href="#" className=""><i className="bx bxl-whatsapp"></i></Link></li>
                        <li className="list-inline-item"><Link href="#" className=""><i className="bx bxl-pinterest-alt"></i></Link></li>
                        <li className="list-inline-item"><Link href="#" className=""><i className="bx bxl-linkedin"></i></Link></li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default ContactInfo

import { UnAuthInputField, UnAuthSubmitButton } from "../../../components/Helpers/FormHelper"
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchData, initialFormState, validateForm } from "../../../components/Helpers/Helper";
import { FORGOT_PASSWORD } from "../../../components/Helpers/ApiRoutes";

const ForgotPassword = () => {
    const { t } = useTranslation();
    const [tinyloader, setTinyloader] = useState(false);

    const submitForm = (e) => {
        if (validateForm(e, "forgot-password-form")) {
            setTinyloader(true)
            let formdata = new FormData(document.getElementById('forgot-password-form'));
            fetchData(FORGOT_PASSWORD, 'POST', formdata, false, true, (res) => {
                setTinyloader(false)
                if (res.status) {
                    initialFormState("forgot-password-form")
                }
            }, false, false, false, "#forgot-password-form")
        }
    }

    return (
        <>
            <form action="#" noValidate className="sign-in-form needs-validation" id="forgot-password-form">
                <h2 className="title">{t("nav.forgot_password")}</h2>
                <UnAuthInputField label={t("form.email")} name="email" id="username" placeholder={t("form.email")} icon="bx bx-user" required />
                <UnAuthSubmitButton type="submit" load={tinyloader} title={t("form.submit")} action={submitForm} />
            </form>
        </>
    )
}

export default ForgotPassword
export const accordian = [
    {
        "title": "accordian1",
        "text": "Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.",
    },
    {
        "title": "accordian2",
        "text": "Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.",
    },
    {
        "title": "accordian3",
        "text": "Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.",
    },
    {
        "title": "accordian1",
        "text": "Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.",
    },
    {
        "title": "accordian2",
        "text": "Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.",
    },
    {
        "title": "accordian3",
        "text": "Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.",
    }
]


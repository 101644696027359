import { useTranslation } from "react-i18next"

const Info = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="container mt-100 mt-60">
                <div className="row align-items-center">
                    <div className="col-lg-4 mb-4">
                        {/* <div className="contact-item mt-40">
                            <div className="float-left">
                                <div className="contact-icon d-inline-block border rounded-pill shadow text-primary mt-1 mr-4">
                                    <i className="bx bx-globe"></i>
                                </div>
                            </div>
                            <div className="contact-details">
                                <h4 className="text-dark mb-1">{t("website")}</h4>
                              <a href={`https://${t("site.web_url")}`}><p className="mb-0 text-muted">{t("site.web_url")}</p></a> 
                              <a href={`https://${t("site.web_url2")}`}><p className="mb-0 text-muted">{t("site.web_url2")}</p></a>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-sm-3 col-2">
                            <div className="float-left">
                                <div className="contact-icon d-inline-block border rounded-pill shadow text-primary mt-1 mr-4">
                                    <i className="bx bx-globe"></i>
                                </div>
                            </div>
                            </div>
                            <div className="col-sm-9 col-10">
                            <div className="contact-details">
                                <h4 className="text-dark mb-1">{t("website")}</h4>
                              <a href={`https://${t("site.web_url")}`}><p className="mb-0 text-muted">{t("site.web_url")}</p></a> 
                              <a href={`https://${t("site.web_url2")}`}><p className="mb-0 text-muted">{t("site.web_url2")}</p></a>
                            </div>
                            </div>
                      
                        </div>
                    </div>

                    <div className="col-lg-4 mb-4">
                        {/* <div className="contact-item mt-40">
                            <div className="float-left">
                                <div className="contact-icon d-inline-block border rounded-pill shadow text-primary mt-1 mr-4">
                                    <i className="bx bx-phone"></i>
                                </div>
                            </div>
                            <div className="contact-details">
                                <h4 className="text-dark mb-1">{t("call_us")}</h4>
                                <div>
                                <a href={`tel:${t("site.phone")}`} className="mb-0 text-muted">{t("site.phone")}</a></div>
                                <div><a href={`tel:${t("site.phone2")}`} className="mb-0 text-muted">{t("site.phone2")}</a></div>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-sm-3 col-2">
                            <div className="float-left">
                                <div className="contact-icon d-inline-block border rounded-pill shadow text-primary mt-1 mr-4">
                                    <i className="bx bx-phone"></i>
                                </div>
                            </div>
                            </div>
                            <div className="col-sm-9 col-10">
                            <div className="contact-details">
                                <h4 className="text-dark mb-1">{t("call_us")}</h4>
                                <div>
                                <a href={`tel:${t("site.phone")}`} className="mb-0 text-muted">{t("site.phone")}</a></div>
                                <div><a href={`tel:${t("site.phone2")}`} className="mb-0 text-muted">{t("site.phone2")}</a></div>
                            </div>
                            </div>
                        </div>
                   
                    </div>

                    <div className="col-lg-4 mb-4">
                        {/* <div className="contact-item mt-40">
                            <div className="float-left">
                                <div className="contact-icon d-inline-block border rounded-pill shadow text-primary mt-1 mr-4">
                                    <i className="bx bxs-envelope"></i>
                                </div>
                            </div>
                            <div className="contact-details">
                                <h4 className="text-dark mb-1">{t("form.email")}</h4>
                                <div>
                                <a href={`mailto:${t("site.email")}`} className="mb-0 text-muted">{t("site.email")}</a>
                                </div>
                                <div>
                                <a href={`mailto:${t("site.email2")}`} className="mb-0 text-muted">{t("site.email2")}</a>
                                </div>
                            </div>
                        </div> */}
                             <div className="row">
                            <div className="col-sm-3 col-2">
                            <div className="float-left">
                                <div className="contact-icon d-inline-block border rounded-pill shadow text-primary mt-1 mr-4">
                                    <i className="bx bxs-envelope"></i>
                                </div>
                            </div>
                            </div>
                            <div className="col-sm-9 col-10">
                            <div className="contact-details">
                                <h4 className="text-dark mb-1">{t("form.email")}</h4>
                                <div>
                                <a href={`mailto:${t("site.email")}`} className="mb-0 text-muted">{t("site.email")}</a>
                                </div>
                                <div>
                                <a href={`mailto:${t("site.email2")}`} className="mb-0 text-muted">{t("site.email2")}</a>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Info

import "./details.scss"
import { useLocation } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import { fetchData, noImageHandle } from "../../components/Helpers/Helper"
import Context from "../../components/Helpers/Context"
import { GET_COMPANY_DETAILS } from "../../components/Helpers/ApiRoutes"
import AboutDetails from "./Components/AboutDetails";


const CompanyDetails = () => {
    const [context] = useContext(Context);
    const [data, setData] = useState({});
    const location = useLocation();

    useEffect(() => {
        let body = document.querySelector("body");
        if (body && body.classList.contains('modal-open')) {
            body.classList.remove('modal-open');
            body.style = "";
            if(document.querySelector(`body .modal-backdrop`)) document.querySelector(`body .modal-backdrop`).remove();
        }
    }, [])

    useEffect(() => {
        if (location && location.state && location.state.company) {
            let user_id = context && context.auth && context.auth.user_details ? context.auth.user_details.id : '';
            fetchData(`${GET_COMPANY_DETAILS}/${location.state.company.user.username}?user_id=${user_id}`, 'GET', '', false, true, (res) => {
                if (res.status) {
                    setData(res.body)
                }
            })
        } else {
            window.history.back()
        }
    }, [location, context])

    return (
        <>
            <div className='companyDetails'>
                <div className="banner-section-contact banner-section-contactnew position-relative" style={{ height: "350px" }}>
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                                <div className="bgWhiteImg">
                                    <div className="company-logo">
                                        <img src={data.logo_url ?? ""} alt="logo" className="rounded-circle" onError={noImageHandle} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container pt-50">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="companyDetailsDiv">
                                        <h1>{data.user && data.user.name}</h1>
                                        <p>{data.industry && data.industry.name}</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 text-end align-self-center">
                                    <div className="followBtnDiv">
                                        {/* <a href="#">+ Follow</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AboutDetails data={data} />
            </div>
        </>
    )
}

export default CompanyDetails
import "./chat.scss";
import Pusher from "pusher-js";
import $ from 'jquery';
import { AuthSection } from "../../components/Layouts/Layout";
import { useContext, useEffect, useState } from "react";
import Context from "../../components/Helpers/Context";
import { chatifyfetcher } from "../../components/Helpers/Helper";
import { CHATIFY } from "../../components/Helpers/ApiRoutes";
import SendMessages from "./Components/SendMessages";
import UserDetails from "./Components/UserDetails";
import { isDate, now } from "lodash";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
TimeAgo.addDefaultLocale(en)

const ChatWithJs = () => {
    const [context] = useContext(Context);
    const [recieverDetails, setRecieverDetails] = useState(null);
    const [clientSendChannel, setClientSendChannel] = useState(null);

    const contactListContainer = "#plist .chatify-contact-list";
    const messagesListContainer = "#messages-list .chatify-messages-list";
    const recieverDetailsContainer = "#chatify-reciever-header";

    useEffect(() => {
        if (document.readyState === "complete" && context && context.auth) {
            const senderId = context.auth.id;
            const pusherChannel = "private-chatify";
            const credential = {
                cluster: process.env.REACT_APP_CLUSTER,
                wsHost: process.env.REACT_APP_WS_HOST,
                wsPort: process.env.REACT_APP_WS_PORT,
                wssPort: process.env.REACT_APP_WSS_PORT,
                encrypted: true,
                forceTLS: false,
                authEndpoint: process.env.REACT_APP_CHATFY_URL + CHATIFY.auth,
                auth: {
                    headers: {
                        "Authorization": "Bearer " + context.auth.access_token,
                    },
                }
            }
            const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, credential);
            if (pusher) {

                var clientSendChannel;
                var clientListenChannel;
                var receiverId;
                var recieverDetails;

                clientListenChannel = pusher.subscribe(`${pusherChannel}.${senderId}`);
                chatifyfetcher(CHATIFY.setActiveStatus, "POST", { status: 1 }, true, false, (res) => { });

                let activeStatusChannel = pusher.subscribe(`presence-activeStatus`);

                chatifyfetcher(`${CHATIFY.getContacts}`, 'GET', '', true, false, (res) => {
                    if (res.status === 200 && res.contacts) {
                        $(contactListContainer).html(res.contacts);
                    }
                });

                activeStatusChannel.bind('pusher:subscription_succeeded', (members) => {
                    members.each((member) => {
                        $(`${contactListContainer} li[data-contact=${member.id}] div.about div.status .active-status`).removeClass('offline').addClass('online');
                        $(`${contactListContainer} li[data-contact=${member.id}] div.about div.status .active-status-text`).removeClass('offline').addClass('online').text('Online');
                    });
                });

                activeStatusChannel.bind('pusher:member_removed', (member) => {
                    $(recieverDetailsContainer).find(".chatify-typing").text('');
                    $(recieverDetailsContainer).find(".chatify-user-lastSeen").text('last seen just now');
                    $(recieverDetailsContainer).find(".chatify-user-lastSeen").attr('data-time', new Date());
                    $(`${contactListContainer} li[data-contact=${member.id}] div.about div.status .active-status`).removeClass('online').addClass('offline');
                    $(`${contactListContainer} li[data-contact=${member.id}] div.about div.status .active-status-text`).removeClass('online').addClass('offline').text('Offline');
                });

                activeStatusChannel.bind('pusher:member_added', (member) => {
                    $(recieverDetailsContainer).find(".chatify-user-status").text('Online');
                    $(recieverDetailsContainer).find(".chatify-user-lastSeen").text('');
                    $(`${contactListContainer} li[data-contact=${member.id}] div.about div.status .active-status`).removeClass('offline').addClass('online');
                    $(`${contactListContainer} li[data-contact=${member.id}] div.about div.status .active-status-text`).removeClass('offline').addClass('online').text('Online');
                });

                $("body").on("click", `${contactListContainer} li`, function () {
                    receiverId = $(this).attr("data-contact");
                    if (clientSendChannel) {
                        pusher.unsubscribe(`${pusherChannel}.${receiverId}`);
                    }
                    clientSendChannel = pusher.subscribe(`${pusherChannel}.${receiverId}`);
                    setClientSendChannel(clientSendChannel);

                    $("#chatify-receiver-id").attr("data-receiver-id", receiverId);
                    $(`${contactListContainer} li`).removeClass("active");
                    $($(this)).addClass("active");

                    let typing = $(`${contactListContainer} li[data-contact=${receiverId}] div.about div.status .typing-status`).text();
                    $(recieverDetailsContainer).find(".chatify-typing").text(typing);

                    chatifyfetcher('idInfo', 'POST', { id: receiverId }, true, false, (res) => {
                        recieverDetails = res;
                        setRecieverDetails(recieverDetails);
                    });

                    chatifyfetcher(CHATIFY.makeSeen, 'POST', { id: receiverId }, true, false, (res) => { });

                    chatifyfetcher(CHATIFY.fetchMessages, 'POST', { id: receiverId }, true, false, (res) => {
                        $(messagesListContainer).html(res.messages);
                        clientSendChannel.trigger("client-seen", { from_id: senderId, to_id: receiverId, seen: true });
                        $(`${contactListContainer} li[data-contact=${receiverId}] div.about div.status .message-count`).addClass("d-none").text(0);
                    });
                });

                clientListenChannel.bind("messaging", function (data) {
                    let message_count = $(`${contactListContainer} li[data-contact=${data.from_id}] div.about div.status .message-count`).text();
                    $(`${contactListContainer} li[data-contact=${data.from_id}] div.about div.status .message-count`).removeClass("d-none").text(parseInt(message_count) + 1);

                    if (`${data.from_id}` === `${receiverId}` && `${data.to_id}` === `${senderId}`) {
                        $(messagesListContainer).append(data.message);
                        chatifyfetcher(CHATIFY.makeSeen, 'POST', { id: receiverId }, true, false, (res) => { })
                        clientSendChannel.trigger("client-seen", { from_id: senderId, to_id: receiverId, seen: true });
                        $(`${contactListContainer} li[data-contact=${receiverId}] div.about div.status .message-count`).addClass("d-none").text(0);
                    }
                });

                clientListenChannel.bind("client-seen", function (data) {
                    if (`${data.from_id}` === `${receiverId}` && `${data.to_id}` === `${senderId}`) {
                        $(messagesListContainer).find(".check-double").html('<i class="fa-solid fa-check-double"></i>');
                    }
                });

                clientListenChannel.bind("client-typing", function (data) {
                    if (`${data.from_id}` === `${receiverId}` && `${data.to_id}` === `${senderId}`) {
                        if (data.typing) {
                            $(recieverDetailsContainer).find(".chatify-typing").text('typing...');
                        } else {
                            $(recieverDetailsContainer).find(".chatify-typing").text('');
                        }
                    }

                    $(`${contactListContainer} li[data-contact=${data.from_id}] div.about div.status .typing-status`).text(data.typing ? 'typing...' : '');
                });

                var timeInterval = setInterval(() => {
                    $(".message-data-time, .chatify-user-lastSeen").each(function () {
                        let time = $(this).attr("data-time");
                        if (isDate(time)) {
                            let timeAgo = new TimeAgo('en-US');
                            let diff_seconds = now() - new Date(time).getTime();
                            let diff_formatted = timeAgo.format(now() - diff_seconds);
                            console.log(diff_formatted);
                            if ($(this).hasClass('chatify-user-lastSeen')) {
                                $(this).text(`last seen ${diff_formatted}`);
                            } else {
                                $(this).text(diff_formatted);
                            }
                        }
                    });
                }, 30000);

                return (() => {
                    clearInterval(timeInterval);
                    if (pusher) {
                        chatifyfetcher('setActiveStatus', 'POST', { status: 0 }, true, false, (res) => { })
                        pusher.unsubscribe(`presence-activeStatus`);
                        pusher.unsubscribe(`${pusherChannel}.${senderId}`);
                        pusher.disconnect();
                    }
                })
            }
        }
    }, [context]);

    return (
        <AuthSection breadCrumbs={<span className="breadcrumb-item active">Chat</span>}>
            <div className="chat-app" id="chatApp">
                <div className="d-none" id="chatify-receiver-id" data-receiver="0"></div>
                <div id="plist" className="people-list">
                    <div className="searchHeadereBar">
                        <section className="main">
                            <div className="search input-group">
                                <input type="text" name="q" placeholder="Search..." autoComplete="off" />
                            </div>
                        </section>
                    </div>
                    <ul className="list-unstyled chat-list mt-2 mb-0 fixedScroll chatify-contact-list"></ul>
                </div>
                <div className="chat" id="messages-list">
                    <UserDetails user={recieverDetails} />
                    <div className="chat-history">
                        <ul className="mb-0 chatify-messages-list">
                            <li className="text-center">
                                <img src={"assets/images/chat.jpg"} alt="chat" height={"400px"} />
                            </li>
                        </ul>
                    </div>
                    <SendMessages senderID={context && context.auth ? context.auth.id : 0} receiverId={recieverDetails && recieverDetails.fetch ? recieverDetails.fetch.id : 0} clientSendChannel={clientSendChannel} messagesListContainer={messagesListContainer} />
                </div>
            </div>
        </AuthSection>
    );
};

export default ChatWithJs;

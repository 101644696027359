import { useTranslation } from "react-i18next"
import Banner from "../../components/Partials/Banner";
const Policy = () => {
    const { t } = useTranslation();

    return (
        <div className="contact-section">
            <Banner title={t("privacy policy")} />
            <section className="section">
                <div className="container">
                    <div className="row">
                        <h1>Privacy Policy</h1>


                        <p>Welcome to Recruitment Portal . We value your trust and are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, share, and protect your personal information when you interact with our recruitment portal. By accessing or using our portal, you consent to the terms described in this Privacy Policy.</p>

                        <h3 className="mt-4">1. Categories of Information Collected:</h3>

                        <p>We collect and process various categories of information to provide our recruitment services and improve your experience on our portal. These categories may include:</p>
                        <li>User Information: This includes your name, email address, contact information, and other details you provide when creating an account or using our services.</li>
                        <li>Job Preferences: Information about your preferred job categories, skills, and career interests.</li>
                        <li>Employment History: Details about your work experience and qualifications.</li>
                        <li>Communication: Information exchanged when you contact us, provide feedback, or participate in surveys.</li>
                        <li>Usage Data: Data about how you interact with our portal, such as pages visited, search queries, and device information.</li>

                        <h3 className="mt-4">2. How We Use Your Information:</h3>

                        <p>We use the collected information to:</p>
                        <li>Provide personalized job recommendations based on your preferences.</li>
                        <li>Connect you with potential employers or job seekers.</li>
                        <li>Improve our services, features, and user experience.</li>
                        <li>Communicate with you about updates, promotions, and relevant news.</li>
                        <li>Enhance security and prevent fraudulent activities.</li>

                        <h3 className="mt-4">3. Sharing Your Information:</h3>

                        <p>We may share your information with:</p>
                        <li>Employers and Job Seekers: To facilitate job matching and recruitment processes.</li>
                        <li>Service Providers: Third-party companies that assist in delivering our services, such as hosting, analytics, and customer support.</li>
                        <li>Legal Requirements: When required by law, such as responding to subpoenas, court orders, or legal processes.</li>
                        <li>Business Transfers: If we undergo a merger, acquisition, or sale, your information may be transferred to the acquiring entity.</li>

                        <h3 className="mt-4">4. Security Measures:</h3>

                        <p>We employ robust security measures to protect your information from unauthorized access, misuse, or disclosure. However, no system can guarantee absolute security, and we cannot guarantee the security of your information.</p>

                        <h3 className="mt-4">5. Your Choices:</h3>

                        <p>You have certain rights and choices concerning your personal information, including the right to access, update, or delete your data. You can manage your preferences in your account settings or contact us for assistance.</p>

                        <h3 className="mt-4">6. Updates to this Policy:</h3>

                        <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for legal, regulatory, or operational reasons. We'll notify you of significant changes, and the revised policy will be effective upon posting.</p>

                        <h3>7. Contact Us:</h3>

                        <p>If you have any questions, concerns, or requests regarding this Privacy Policy or your personal information, please contact us at xyz@gmail.com.</p>

                        <p>By using our recruitment portal, you acknowledge that you have read and understood this Privacy Policy and consent to the practices described herein.</p>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Policy
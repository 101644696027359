import _ from "lodash";

const SideFilter = (props) => {

    const inputChange = (e) => {
        let item = props.query;
        if (e.target.checked) {
            item = [...item, ...e.target.value];
        } else {
            let index = item.indexOf(e.target.value);
            if (index > -1) item.splice(index, 1);
        }
        props.setQuery(item);
        props.refresh()
    }

    return (
        <>
            {props.data && props.data.length > 0 && <>
                <div className="job-time">
                    <div className="job-time-title">{props.title}</div>
                    <div className="job-wrapper">
                        {props.data.map((item, key) => {
                            return (
                                <div key={key} className="type-container">
                                    <div>
                                    <input type="checkbox" id={`${props.title}_${key}`} value={item.value} className="job-style" onChange={inputChange} checked={props.query.includes(_.toString(item.value))} />
                                    <label htmlFor={`${props.title}_${key}`}>{item.label}</label>
                                    </div>
                                    <div>
                                    {/* <span className="job-number">{item.jobs.length}</span> */}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </>}
        </>
    )
}

export default SideFilter
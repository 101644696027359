import "./signin.scss"
import { useState } from 'react';
import AnimateSection from './Components/AnimateSection';
import ForgotPassword from "./Components/ForgotPassword";
import ResetPassword from "./Components/ResetPassword";
import { useLocation } from "react-router-dom";

const ForgotAccount = () => {
    const location = useLocation()
    const [resetClass] = useState((location && location.pathname === '/reset-password') ? 'sign-up-mode' : '');

    return (
        <div className="signin-section">
            <div className={`login-section ${resetClass}`}>
                <div className="forms-container  animate-top">
                    <div className="signin-signup">
                        <ForgotPassword />
                        <ResetPassword />
                    </div>
                </div>
                <AnimateSection setSignIn={false} />
            </div>
        </div>
    )
}

export default ForgotAccount
import "./chat.scss";
import { AuthSection } from "../../components/Layouts/Layout";
import Pusher from "pusher-js";
import { useContext, useEffect, useMemo, useState } from "react";
import Context from "../../components/Helpers/Context";
import { fetchAllContact, fetchMessageAndUserDetails, listenMessagingEvent, listenSeenEvent, listenTypingEvent, pusherChannel, pusherCredential, pusherDisconnect, pusherSubscription, setReceiverAndRecentReceiverId, whenMessageChange } from "./Components/ChatifyHelper";
import { chatifyfetcher } from "../../components/Helpers/Helper";
import UserList from "./Components/UserList";
import UserDetails from "./Components/UserDetails";
import SendMessages from "./Components/SendMessages";
import { ModalSection } from "../../components/Helpers/ModalHelper";
import JobDetail from "../Job/Components/JobDetail";
import Messages from "./Components/Messages";
import { CHATIFY } from "../../components/Helpers/ApiRoutes";

const Chat = () => {
    const [context] = useContext(Context);
    const [pusher, setPusher] = useState(null);
    const [member, setMember] = useState([]);
    const [memberActivity, setMemberActivity] = useState([]);
    const [reciever, setReciever] = useState(null);
    const [recentReciever, setRecentReciever] = useState(null);
    const [recieverId, setRecieverId] = useState(null);
    const [clientListenChannel, setClientListenChannel] = useState(null);
    const [clientSendChannel, setClientSendChannel] = useState(null);
    const [messageList, setMessageList] = useState({});
    const [contactList, setContactList] = useState("");
    const [seen, setSeen] = useState(false);
    const [job, setJob] = useState(null);
    const[mobileCheck,setMobileCheck]=useState(false);

    const recieverDetails = useMemo(() => {
        // get only current reciever details from member, reciever and memberActivity state.
        if (reciever) {
            let activity = memberActivity.filter((item) => `${item.from_id}` === `${reciever.fetch.id}`);
            let user = member.filter((item) => `${item.id}` === `${reciever.fetch.id}`);
            let details = reciever;

            if (activity && activity.length > 0) details = { ...reciever, ...activity[0] };
            if (user && user.length > 0) details = { ...user[0], ...details, active: true, lastSeen: null };
            else details = { ...details, active: false, lastSeen: details.lastSeen ?? null, lastSeen_at: null };

            return details;
        } else return null;
    }, [reciever, member, memberActivity]);

    useEffect(() => {
        if (context && context.auth) {
            // Pusher.logToConsole = true;
            setPusher(new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, pusherCredential(context.auth.access_token)));
        }
    }, [context]);

    useEffect(() => {
        // presence-channel and clientListenChannel subcribe. get all contacts list from the api. when remove this page than automatically remove/unsubscribe and disconnect from pusher.
        if (pusher) {
            pusherSubscription(pusher, setMember);
            setClientListenChannel(pusher.subscribe(`${pusherChannel}.${context.auth.id}`));
            fetchAllContact(setContactList);
            return () => pusherDisconnect(pusher, context.auth);
        }
    }, [context, pusher]);

    useEffect(() => {
        // get current and one previous receiver id.
        if (contactList) {
            setSeen(false);
            setReceiverAndRecentReceiverId(setRecieverId, setRecentReciever);
        }
    }, [contactList]);

    useEffect(() => {
        // when reciever changed than trigger typing false event of privious receiver.
        if (recentReciever) {
            clientSendChannel.trigger("client-typing", { from_id: context.auth.id, to_id: recentReciever, typing: false });
        }
    }, [recentReciever, context, clientSendChannel]);

    useEffect(() => {
        // when reciever changed than subscribe new updated recipient channel.
        if (recieverId) {
            setClientSendChannel(pusher.subscribe(`${pusherChannel}.${recieverId}`));
            fetchMessageAndUserDetails(recieverId, setMessageList, setReciever, setSeen);
        }
    }, [recieverId, pusher]);

    useEffect(() => {
        // when only seen true than trigger seen event of currnet receiver
        if (seen && reciever && reciever.fetch) {
            chatifyfetcher(CHATIFY.makeSeen, 'POST', { id: reciever.fetch.id }, true, false, (res) => { })
            clientSendChannel.trigger("client-seen", { from_id: context.auth.id, to_id: reciever.fetch.id, seen: true });
        }
    }, [context, reciever, clientSendChannel, seen]);

    useEffect(() => {
        // listen messaging, client-seen and client-typing triggered events.
        if (reciever) {
            listenMessagingEvent(clientListenChannel, setMessageList, context.auth.id, reciever.fetch.id, setSeen);
            listenTypingEvent(clientListenChannel, setMemberActivity);
            listenSeenEvent(clientListenChannel);
        }
    }, [context, reciever, clientListenChannel]);

    useEffect(() => {
        // when messages recieved than apply some event like time changes etc.
        if (context && context.auth) {
            setTimeout(() => whenMessageChange(context.auth.id, setJob), 0);
        }
    }, [context, messageList]);

    return (
        <AuthSection breadCrumbs={<span className="breadcrumb-item active">Chat</span>}>
            <div className="chat-app" id="chatApp">
                <div className={mobileCheck?"dNone":"dBlock"} onClick={()=>setMobileCheck(true)}>
                <UserList users={contactList} />
                </div>
                <div className={mobileCheck?"dBlock chat":"dNone chat"}>
                    <UserDetails setMobileCheck={setMobileCheck} user={recieverDetails} />
                    <Messages messages={messageList} />
                    <SendMessages sender={context && context.auth} user={recieverDetails} setMessageList={setMessageList} clientSendChannel={clientSendChannel} />
                </div>
            </div>
            <ModalSection title={job && job.title} modal_id="chat-job-details-view-modal" size="xl">
                <JobDetail job={job} />
            </ModalSection>
        </AuthSection>
    );
};

export default Chat;

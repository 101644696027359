import "./job.scss"
import { useTranslation } from "react-i18next"
import SearchHeader from "./Components/SearchHeader"
import SideFilter from "./Components/SideFilter"
import CandidateItem from "./Components/CandidateItem"
import { useContext, useEffect, useState } from "react"
import { fetchData, showAlert } from "../../components/Helpers/Helper"
import { CANDIDATES_FILTER, CANDIDATES, BOOKMARK_CANDIDATE_CATEGORY, CANDIDATE_BOOKMARK } from "../../components/Helpers/ApiRoutes"
import Pagination from "../../components/Helpers/Pagination"
import { now } from "lodash"
import Context from "../../components/Helpers/Context"
import { ModalSection } from "../../components/Helpers/ModalHelper"
import { ReactSelect } from "../../components/Helpers/FormHelper"

const Candidate = () => {
    const { t } = useTranslation();
    const [data, setData] = useState({});
    const [filter, setFilter] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [education, setEducation] = useState([]);
    const [experience, setExperience] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [query, setQuery] = useState({ profession: '', country: '', keyword: '', gender: '', sort_by: '' });
    const [context] = useContext(Context);
    const [candidateId, setCandidateId] = useState('')
    const [category, setCategory] = useState();
    const [categoryId, setCategoryId] = useState();
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        fetchData(CANDIDATES_FILTER, 'GET', '', false, true, (res) => {
            if (res.status) {
                setFilter(res.body)
            }
        })
    }, [])

    useEffect(() => {
        if (context && context.auth && context.auth.user_details) {
            fetchData(`${BOOKMARK_CANDIDATE_CATEGORY}?company_id=${context.auth.user_details.id}`, 'GET', '', true, true, (res) => {
                if (res && res.body) {
                    setCategory(res.body);
                }
            })
        }
    }, [context])

    useEffect(() => {
        let api_url = `${CANDIDATES}?experience=${experience}&education=${education}&sort_by=${query.sort_by}&gender=${query.gender}&profession=${query.profession}&country=${query.country}&keyword=${query.keyword}`;
        if (context && context.auth && context.auth.user_details) {
            api_url += `&user_id=${context.auth.user_details.id}`;
        }
        const controller = new AbortController();
        const signal = controller.signal;
        fetchData(api_url, 'GET', '', false, true, (res) => {
            if (res.status) {
                setData(res.body)
            }
        }, signal)
        return () => controller.abort()
    }, [currentPage, experience, education, query, refresh, context])

    const addToFav = () => {
        if (categoryId && categoryId.value) {
            setDisabled(true)
            fetchData(CANDIDATE_BOOKMARK, 'POST', { category_id: categoryId.value, candidate_id: candidateId }, true, false, (res) => {
                setDisabled(false)
                if (res && res.message) {
                    document.querySelector('#categoryAddModal [data-bs-dismiss="modal"]').click()
                    setRefresh(now())
                }
            })
        } else {
            showAlert({ message: "Please select  a category", status: false });
        }
    }

    return (
        <>
            <section className="job-section">
                <div className="job">
                    <div className="job-wrapper">
                        <SearchHeader setQuery={setQuery} data={filter} />
                        <div className="main-container">
                            <div className="search-type">
                                <SideFilter refresh={() => setRefresh(now())} query={experience} setQuery={setExperience} title="Experience" data={filter && filter.experiences} />
                                <SideFilter refresh={() => setRefresh(now())} query={education} setQuery={setEducation} title="Educations" data={filter && filter.educations} />
                            </div>
                            <div className="searched-jobs">
                                <div className="searched-bar mb-3">
                                    <div className="searched-show">Showing {data.total ?? 0} Candidate</div>
                                    <select className="form-select" aria-label="Default select example" onChange={(e) => setQuery(prev => ({ ...prev, sort_by: e.target.value }))}>
                                        <option value="Recommended">{t("Recommended")}</option>
                                        <option value="latest">{t("latest")}</option>
                                        <option value="oldest">{t("oldest")}</option>
                                    </select>
                                </div>
                                {data.data && <>
                                    {data.data.length > 0 ? data.data.map((item, key) => <CandidateItem key={key} data={item} setCandidateId={setCandidateId} setRefresh={setRefresh} disabled={disabled} setDisabled={setDisabled} />) : <>
                                        <div className="find-search-image  w3-animate-bottom">
                                            <img src="/img/new-found.png" alt="find-search" className="w-100 find-search-img" />
                                        </div>
                                    </>}
                                </>}
                                <div className="row">
                                    <div className="col-sm-12">
                                        <Pagination paginate_data={data} onPageChange={(page) => setCurrentPage(page.selected >= 0 ? page.selected + 1 : 0)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ModalSection modal_id="categoryAddModal" title="Add Bookmark Category" size="" footer={<button disabled={disabled} type="button" className="btn btn-sm btn-success" onClick={addToFav}> Add</button>}>
                <div className="search-bar" style={{ height: "100px" }}>
                    <ReactSelect name="bookmark-category" label={t("Bookmark Category")} required options={category} value={categoryId} placeholder={t("category")} onChange={(e) => setCategoryId(e)} size="col-md-12" />
                </div>
            </ModalSection>
        </>
    )
}
export default Candidate
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import hi from "./components/Lang/hi.json";
import en from "./components/Lang/en.json";
import ar from "./components/Lang/ar.json";

const resources = {
    hi: { translation: hi },
    en: { translation: en },
    ar: { translation: ar },
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: localStorage.getItem('localization') ?? 'en',
        fallbackLng: 'en',
    });

export default i18n;

import { Link } from "react-router-dom";
import { fetchData, noImageHandle } from "../../../components/Helpers/Helper";
import { useContext } from "react";
import { useTranslation } from "react-i18next"
import Context from "../../../components/Helpers/Context";
import { isCompany } from "../../../components/Helpers/RoleHelper";
import { CANDIDATE_BOOKMARK } from "../../../components/Helpers/ApiRoutes";
import { now } from "lodash";
const CandidateItem = ({ data, setCandidateId, setRefresh, disabled, setDisabled }) => {
    const [context] = useContext(Context);
    const { t } = useTranslation();

    const removeToFav = (candidate_id) => {
        setDisabled(true)
        fetchData(CANDIDATE_BOOKMARK, 'POST', { candidate_id }, true, false, (res) => {
            setDisabled(false)
            if (res && res.message) {
                setRefresh(now())
            }
        })
    }

    return (
        <>
            <section>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="job-card-two">
                            <div className="row align-items-center">
                                <div className="col-12 col-xl-2 d-flex justify-content-center items-align-center">
                                    <div className="company-logo">
                                        <Link className="manage_icon dada" to="/candidate-details" state={{ data: data.user && data.user.id }}>
                                            <img src={data.photo && data.photo} alt="logo" onError={noImageHandle} />
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-8">
                                    <div className="job-info">
                                        <h3>
                                            <p>{data.user && data.user.name}</p>
                                        </h3>
                                        <ul>
                                            <li className="me-2">
                                                <i className="bx bx-briefcase"></i> {data.user && data.user.email}
                                            </li>
                                            <li className="me-2">
                                                <i className="bx bx-location-plus"></i> {data.full_address}
                                            </li>
                                            {data.education && <li className="me-2">
                                                <i className="bx bx-briefcase"></i> {data.education.name}
                                            </li>}
                                            {data.experience && <li className="me-2">
                                                <i className="bx bx-briefcase"></i> {data.experience.name}
                                            </li>}
                                            {data.job_role && <li className="me-2">
                                                <i className="bx bx-briefcase"></i> {data.job_role.name}
                                            </li>}
                                            {data.profession && <li className="me-2">
                                                <i className="bx bx-briefcase"></i> {data.profession.name}
                                            </li>}
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-2">
                                    <div className="d-flex align-items-center">
                                        <div className="theme-btn text-end">
                                            <Link to="/candidate-details" state={{ data: data.user && data.user.id }} className="default-btn">{t("Details")}</Link>
                                        </div>
                                        <div className="JobfavrateIcon">
                                            {isCompany && context && context.auth && <>
                                                {data.bookmarked ? <>
                                                    <button className="btn border-0" onClick={() => removeToFav(data && data.id)} disabled={disabled}><i className={`fa fa-heart redHeart`} ></i></button>
                                                </> : <>
                                                    <button data-bs-toggle="modal" data-bs-target="#categoryAddModal" className="btn border-0" disabled={disabled} onClick={() => setCandidateId(data && data.id)} ><i className={`fa fa-heart`} ></i></button>
                                                </>}
                                            </>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
export default CandidateItem
import { useContext, useEffect, useState } from "react";
import ReactDom from "react-dom";
import { createRoot } from "react-dom/client";
import _, { now } from "lodash";
import DataTables, { reloadDataTable } from "../Helpers/Datatables";
import { ModalSection } from "../Helpers/ModalHelper";
import { fetchData, noImageHandle, showAlert } from "../Helpers/Helper";
import { ViewButtonModal } from "../Helpers/ButtonHelper";
import JobDetail from "../../modules/Job/Components/JobDetail";
import Context from "../Helpers/Context";
import { useTranslation } from "react-i18next";
import { isCandidate } from "../Helpers/RoleHelper";
import { GET_JOB_DETAILS } from "../Helpers/ApiRoutes";
import { Navigate } from "react-router-dom";

const JobList = (props) => {
  const [job, setJob] = useState(null);
  const [reload, setReload] = useState(0);
  const [resumes, setResumes] = useState({});
  const [context] = useContext(Context);
  const { t } = useTranslation();
  const [data, setData] = useState({});
  const [applied, setApplied] = useState(false);
  const [dt] = useState({
    dt_url: props.listUrl,
    dt_name: "jobs-list",
    dt_column: [
      {
        data: "DT_RowIndex",
        name: "id",
        title: "",
        sortable: false,
        searchable: true,
        className: "noExport",
      },
      {
        data: "logo_url",
        name: "logo_url",
        title: "Logo",
        sortable: false,
        searchable: true,
      },
      { data: "title", name: "title", title: "Job Title", searchable: true },
      { data: "is_remote", name: "is_remote", title: "Remote Job" },
      { data: "min_salary", name: "min_salary", title: "Min Salary" },
      { data: "max_salary", name: "max_salary", title: "Max Salary" },
      {
        data: "apply_on",
        name:
          props.from && props.from === "fav"
            ? "bookmark_candidate_job.created_at"
            : "applied_jobs.created_at",
        title: "Date",
      },
      { data: "status", name: "status", title: "Status" },
      {
        data: "id",
        name: "id",
        title: "Action",
        sortable: false,
        searchable: true,
        className: "noExport",
      },
    ],
    dt_column_defs: [
      {
        targets: 1,
        createdCell: (td, cellData, records, row, col) => {
          createRoot(td).render(
            <>
              <img
                src={records.company.logo_url}
                className="border rounded-circle"
                style={{ width: "65px", height: "65px" }}
                alt="logo"
                onError={noImageHandle}
              />
            </>
          );
        },
      },
      {
        targets: 3,
        createdCell: (td, cellData, records, row, col) => {
          createRoot(td).render(
            <>
              <span
                className={`text-${records.is_remote === 1 ? "success" : "danger"
                  }`}
              >
                {records.is_remote === 1 ? "Yes" : "No"}
              </span>
            </>
          );
        },
      },
      {
        targets: 7,
        createdCell: (td, cellData, records, row, col) => {
          createRoot(td).render(
            <>
              <span
                className={`text-${records.status === "active" ? "success" : "danger"
                  }`}
              >
                {_.upperFirst(records.status)}
              </span>
            </>
          );
        },
      },
      {
        targets: 8,
        createdCell: (td, cellData, records, row, col) => {
          createRoot(td).render(
            <>
              {props.from && props.from === "fav" && (
                <button
                  className="btn btn-sm btn-outline-danger me-2"
                  title="Remove Bookmark"
                  onClick={() => removeToFav(records.slug)}
                >
                  <i className="bi bi-bookmark-fill"></i>
                </button>
              )}
              <ViewButtonModal
                modal_id="#viewJobList"
                action={() => setJob(records)}
              />
            </>
          );
        },
      },
    ],
  });

  useEffect(() => {
    reloadDataTable(dt, props.listUrl);
  }, [dt, props.listUrl, reload]);
  useEffect(() => {
    if (job) {
      let user_id = context && context.auth && context.auth.id;
      fetchData(
        `${GET_JOB_DETAILS}/${job.slug}?user_id=${user_id}`,
        "GET",
        "",
        false,
        true,
        (res) => {
          if (res.status) {
            setData(res.body.job);
            setApplied(res.body.job.applied);
            // setResumes(res.body.resumes && res.body.resumes.length > 0 ? res.body.resumes[0] : false)
          }
        }
      );
    }
  }, [job]);
  const removeToFav = (slug) => {
    fetchData(`job/${slug}/bookmark`, "POST", "", true, false, (res) => {
      if (res.status) setReload(now());
    });
  };

  const applyJob = () => {
    if (context && context.auth) {
      if (isCandidate) {
        if (applied) {
          showAlert({
            message: t("you_are_already_applied_this_job"),
            status: false,
          });
        } else {
          if (resumes && resumes.id) {
            fetchData(
              `job/${data.slug}/apply`,
              "POST",
              { resume_id: resumes && resumes.id, cover_letter: "yes" },
              true,
              false,
              (res) => {
                if (res.status) {
                  setApplied(true);
                  document.getElementById("closeJob").click();
                  document.getElementById("mainModal").click();
                }
              }
            );
          } else {
            showAlert({
              message: t("please_complete_your_profile_first"),
              status: false,
            });
          }
        }
      } else {
        showAlert({ message: t("please_login_as_a_candidate"), status: false });
      }
    } else {
      showAlert({
        message: t("please_login_as_a_candidate_for_apply_a_job"),
        status: false,
      });
      Navigate("/sign-in");
    }
  };

  const checkSelectedResume = (id) => {
    if (!resumes.id) {
      showAlert({
        message: t("please_select_your_resume"),
        status: false,
      });
    } else {
      applyJob();
    }
  };
  const openPreviousModal = () => {
    document.getElementById("closeJob").click();
  };
  const closeApplyJobcByid = () => {
    document.getElementById("mainModal").click();
  };
  const openOpenLastModal = () => {
    document.getElementById("closeApplyJobByid").click();
  };
  console.log("ooo");
  return (
    <>
      <DataTables dt={dt} dt_name="jobs-list" />
      {ReactDom.createPortal(1, document.getElementById("second_root"))}
      <ModalSection
        close_btn_id="closeJob"
        title={job && job.title}
        footer={
          <div className="row">
            <div className="theme-btn">
              <button
                data-toggle="modal"
                data-target={applied ? "" : "#applyHereJob"}
                className="default-btn"
                id="mainModal"
                onClick={() => openPreviousModal()}
              >
                {applied ? t("applied") : t("apply_now")}
              </button>
            </div>
          </div>
        }
        modal_id="viewJobList"
        size="xl"
      >
        <JobDetail job={job} />
      </ModalSection>
      <div>
        <ModalSection
          title="Apply Job"
          modal_id="applyHereJob"
          size="xl"
          footer={
            <div className="row">
              <div className="theme-btn">
                <button
                  className="default-btn"
                  onClick={() => checkSelectedResume()}
                >
                  {applied ? t("applied") : t("apply_now")}
                </button>
              </div>
            </div>
          }
          action={() => closeApplyJobcByid()}
        >
          <div className="row">
            <div className="main-pdf-box1">
              {context?.auth?.resume_files?.map((resume, index) => {
                return (
                  <>
                    <div
                      className={`mb-3 pdf-box ${resumes.id === resume.id ? "resume_selected" : ""
                        }`}
                      key={index}
                      onClick={() => {
                        setResumes(resume);
                      }}
                    >
                      <div className="pdf-icon position-relative">
                        <img src="./images/pdf.png" alt="" />
                        {/* <div className="show-button">
                          <div className="preview-btn">
                          </div>
                        </div> */}
                        <div className="show-button showBtnNew" data-toggle="modal"
                          data-target={"#viewResumeJob_" + index}
                          id="closeApplyJobByid">
                          <div className="preview-btn">
                            <i className="fa-solid fa-eye"></i>
                          </div>
                        </div>
                      </div>
                      <p className="text-center mt-1 p-0 m-0">
                        {resume.name.substring(0, 20)}
                        {resume.name.length > 20 && "..."}
                      </p>
                      {/* <button
                        data-toggle="modal"
                        data-target={"#viewResumeJob_" + index}
                        className="default-btn"
                        id="closeApplyJobByid"
                      >
                        {"view"}
                      </button> */}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </ModalSection>
      </div>
      <div className="mt-5">
        {context?.auth?.resume_files?.map((resume, index) => {
          return (
            <>
              <ModalSection
                modal_id={"viewResumeJob_" + index}
                title="Resume"
                size="xl"
                key={index}
                close_btn_id="closeLastModal"
                action={() => openOpenLastModal()}
              >
                <iframe
                  className="priview_pdf"
                  src={process.env.REACT_APP_ASSET_URL + resume.file}
                  title="Resume 1"
                ></iframe>
              </ModalSection>
            </>
          );
        })}
      </div>
    </>
  );
};

export default JobList;

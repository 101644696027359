import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const AnimateSection = ({ setSignIn }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="panels-container ">
                <div className="panel left-panel animate-right">
                    <div className="content">
                        <h3>{t("new_here")}</h3>
                        <p>{t("lorem_ipsum")}</p>
                        {setSignIn && <button className="btn transparent" id="sign-up-btn" onClick={() => setSignIn('sign-up-mode')}>
                            {t("Sign-up")}
                        </button>}
                        <Link to="/" className="btn transparent">{t("nav.home")}</Link>
                    </div>
                    <img src="https://i.ibb.co/6HXL6q1/Privacy-policy-rafiki.png" className="image" alt="" />
                </div>
                <div className="panel right-panel animate-left">
                    <div className="content">
                        <h3>{t("one_of_us")}</h3>
                        <p>{t("lorem_ipsum")}</p>
                        {setSignIn && <button className="btn transparent" id="sign-in-btn" onClick={() => setSignIn('')}>
                            {t("nav.sign_in")}
                        </button>}
                        <Link to="/" className="btn transparent">{t("nav.home")}</Link>
                    </div>
                    <img src="https://i.ibb.co/nP8H853/Mobile-login-rafiki.png" className="image" alt="" />
                </div>
            </div>
        </>
    )
}

export default AnimateSection
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Aside from "./Aside";
import { useEffect, useState } from "react";
import { scrollbarSetToTop } from "../Helpers/Helper";
import FrontendHeader from "./FrontendHeader";
import FrontendFooter from "./FrontendFooter";
import Header from "./Header";
import { useTranslation } from "react-i18next"
import BreadCrumbs from "./BreadCrumbs";
import ManagePage from "./ManagePage";
import { useContext } from "react";
import Context from "../Helpers/Context";

export const Layout = (props) => {
    const locatoin = useLocation()
    const [load, setLoad] = useState(false);

    useEffect(() => {
        scrollbarSetToTop();
        setLoad(true)
    }, [locatoin.pathname])

    return (
        <>
            {load ? <>
                <FrontendHeader />
                <Outlet />
                <FrontendFooter />
            </> : <ManagePage />}
        </>
    )
}


export const AuthLayout = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [context] = useContext(Context);
    const [load, setLoad] = useState(false);

    useEffect(() => {
        scrollbarSetToTop()
        let token = localStorage.getItem('recruitment-token')
        if (token && !props.authorized) {
            navigate('/dashboard')
        } else if (!token && props.authorized) {
            navigate('/sign-in')
        }
        setLoad(true)
    }, [navigate, props]);

    useEffect(() => {
        if (context && context.auth) {
            if (context.auth.user_details && context.auth.user_details.profile_complete !== 0 && window.location.pathname !== '/setting') navigate('/setting');
        }
    }, [context, navigate])

    return (
        <>
            {load ? <>
                {props.authorized ? <>
                    <div id="wrapper" className="theme-cyan">
                        <div className="page-loader-wrapper d-none1">
                            <div className="loader">
                                <div className="m-t-30">
                                    <div className="spinner-border spinner-border-lg mx-3 text-white" role="status"><span className="visually-hidden"></span></div>
                                </div>
                                <p>{t("please_wait")}</p>
                            </div>
                        </div>
                        <Header />
                        <Aside />
                        <div id="main-content">
                            <Outlet />
                        </div>
                    </div>
                </> : <Outlet />}
            </> : <ManagePage />}
        </>
    )
}

export const AuthSection = (props) => {

    return (
        <div className="container-fluid">
            <BreadCrumbs btn={props.btn}>
                {props.breadCrumbs}
            </BreadCrumbs>
            <div className="row clearfix">
                <div className="col-lg-12">
                    <div className="card cardNew" style={{ boxShadow: "5px 5px 10px #888888" }}>
                        <div className="body">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Layout;
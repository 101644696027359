import { noImageHandle } from "../../../components/Helpers/Helper"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

const BlogItems = ({ data }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="col-lg-4 mb-4">
                <div className="flip-card">
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img data-cfsrc={data.image_url} alt="blog" src={data.image_url} onError={noImageHandle} />
                            <div className="blog-text">
                                <ul>
                                    <li>
                                        <i className="bx bxs-user"></i> {data.author && data.author.name}
                                    </li>
                                    <li>
                                        <i className="bx bx-calendar"></i> {data.created_at}
                                    </li>
                                </ul>
                                <Link to="/blog-details">{t("Read More")} <i className="bx bx-plus bx-spin"></i></Link>
                            </div>
                        </div>
                        <div className="flip-card-back">
                            <div className="blog-text">
                                <h3 className="text-white">
                                    {data.title}
                                </h3>
                                <ul>
                                    <p className="text-white">
                                        {data.short_description}
                                    </p>
                                </ul>
                                <Link to="/blog-details" state={{slug: data.slug}}>{t("Read More")} <i className="bx bx-plus bx-spin"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogItems

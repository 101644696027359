import React from 'react'
import "../details.scss";

function AboutDetails({ data }) {
    return (
        <>
            <section className="job-details pt-4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="job-details-text">
                                        <div className="details-text">
                                            <h3>About us</h3>
                                            <div dangerouslySetInnerHTML={{ __html: data.bio }}></div>
                                        </div>
                                        <div className="details-text mt-5">
                                            <div dangerouslySetInnerHTML={{ __html: data.vision }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="key-highlights details-text" data-uba-view="keyHighlights" id="keyHighlights">
                                <div className="key-highlights-title">
                                    <h3>Key highlights</h3>
                                </div>
                                <div className="key-highlights-body">
                                    <div className="common-card">
                                        <div className="common-card-icon"><img alt="" src="//static.naukimg.com/s/7/109/assets/images/img_Company-Culture-ot.580452b3.png" width="48" height="48" /></div>
                                        <div className="common-card-desc">
                                            <div className="name">Organization</div>
                                            <div className="label">{data.organization && data.organization.name}</div>
                                        </div>
                                    </div>
                                    <div className="common-card">
                                        <div className="common-card-icon"><img alt="" src="//static.naukimg.com/s/7/109/assets/images/img_Salary-ot.7d8c7157.png" width="48" height="48" /></div>
                                        <div className="common-card-desc">
                                            <div className="name">Industry</div>
                                            <div className="label">{data.industry && data.industry.name}</div>
                                        </div>
                                    </div>
                                    <div className="common-card">
                                        <div className="common-card-icon"><img alt="" src="https://static.ambitionbox.com/alpha/company/photos/company_114/team/5448a1f228495f6e4604c12a484f80ad8f0753d2.jpg" width="48" height="48" /></div>
                                        <div className="common-card-desc">
                                            <div className="name">Team Size</div>
                                            <div className="label">{data.team_size && data.team_size.name}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="awards-recog details-text mt-5" data-uba-view="awards" id="awards">
                                <div className="awards-title"><img className="awards-img" src="//static.naukimg.com/s/7/109/assets/images/award-new-ot.58e435ce.svg" alt="award img" height="30" width="40" />
                                    <h3>Awards &amp; Recognitions</h3>
                                </div>
                                <div className="awards-details">
                                    <div className="item">
                                        <div className="desc">
                                            <div className="year">2023</div>
                                            <div className="title">The Economic Times Best Workplaces for Women
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="desc">
                                            <div className="year">2022</div>
                                            <div className="title">Hired Brand Health Report, World’s Most Desirable Employers
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="desc">
                                            <div className="year">2021</div>
                                            <div className="title">AnitaB Top Companies for Women Technologists
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="desc">
                                            <div className="year">2020</div>
                                            <div className="title">Careers and the Disabled Magazine Top 50 Employers
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="desc">
                                            <div className="year">2019</div>
                                            <div className="title">Fast Company’s Best Workplaces for Innovators
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutDetails
import { Link, useNavigate } from "react-router-dom";
import ChangeLanguage from "../Lang/ChangeLanguage";
import { useTranslation } from "react-i18next"
import { fetchData } from "../Helpers/Helper";
import { useContext, useEffect, useState } from "react";
import Context from "../Helpers/Context";
import { LOGOUT, READ_NOTIFICATIONS, UNREAD_NOTIFICATIONS } from "../Helpers/ApiRoutes";
const Header = () => {
    const { t } = useTranslation();
    const [, setContext] = useContext(Context)
    const [unreadNotificationCount, setUnreadNotificationCount] = useState(0)
    const navigate = useNavigate()

    useEffect(() => {
        var jsElm = document.createElement("script");
        jsElm.type = "application/javascript";
        jsElm.src = 'assets/js/common.js';
        document.body.appendChild(jsElm);
    }, [])

    useEffect(() => {
        function readNotification() {
            fetchData(UNREAD_NOTIFICATIONS, 'GET', '', true, false, (res) => {
                if (res.status) {
                    setUnreadNotificationCount(prev => res.body.unread_count)
                }
            })
        }
        readNotification()
        let notificationInterval = setInterval(() => readNotification(), [10000*5])
        return (() => clearInterval(notificationInterval))
    }, [])

    const handleLogout = () => {
        fetchData(LOGOUT, 'POST', '', true, false, (res) => {
            if (res.status) {
                setContext(prevState => ({ ...prevState, auth: '' }))
                localStorage.removeItem("recruitment-token");
                navigate("/sign-in")
            }
        })
    }

    const markAsRead = () => {
        fetchData(READ_NOTIFICATIONS, 'POST', '', true, false, (res) => {
            if (res.status) {
                setUnreadNotificationCount(prev => res.body.unread_count)
            }
        })
    }

    return (
        <>
            <nav id="nav-sidebar" className="navbar navbar-fixed-top">
                <div className="container-fluid">
                    <div className="navbar-brand">
                        <button type="button" className="btn-toggle-offcanvas me-2">
                            <i className="fa fa-bars"></i>
                        </button>
                        <button type="button" className="btn-toggle-fullwidth">
                            <i className="fa fa-bars"></i>
                            </button>
                        <Link to="/"><i className="fa fa-home"></i> {t("recruitment_portal")}</Link>
                    </div>

                    <div className="navbar-right">
                        <div id="navbar-menu">
                            <ul className="nav navbar-nav">
                                <li>
                                    <div className="dropdown notification-css">
                                        <button className="dropbtn icon-menu">
                                            <i className="fa fa-bell"></i>
                                            <span className="number" id="bell-notification-count">{unreadNotificationCount}</span>
                                        </button>
                                        <div className="dropdown-content p-2 rounded">
                                            <button disabled={!unreadNotificationCount} className="btn btn-primary w-100 mb-1 text-start" onClick={markAsRead}>{t("mark_as_read")}</button>
                                            <button className="btn btn-primary w-100 text-start" onClick={() => navigate('/notifications')}>{t("go_to_notification")}</button>
                                        </div>
                                    </div>
                                </li>
                                <li title="Logout">
                                    <button title="Logout" className="icon-menu" onClick={handleLogout}><i className="fa fa-power-off"></i></button>
                                </li>
                            </ul>
                        </div>
                        <ChangeLanguage />
                    </div>
                </div>
            </nav>
        </>
    )
}
export default Header
import { useState } from "react";
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom";
import Select from 'react-select'

const BannerSection = ({ data, roles }) => {
    const { t } = useTranslation();
    const [filter, setFilter] = useState({ keyword: "", location: "", job_roles: "" });

    return (
        <>
            <div className="banner-section">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="banner-content text-center">
                                <p>{t("find_jobs_employment_career_opportunities")}</p>
                                <h1>{t("drop_resume_get_your_desire_job")}</h1>
                                <form className="banner-form">
                                    <div className="row">
                                        <div className="mt-3 mt-lg-0 col-lg-3">
                                            <div className="form-group">
                                                <label htmlFor="keyword">{t("Job_keyword")}:</label>
                                                <input type="text" className="form-control" id="keyword" placeholder={t("Job_keyword")} value={filter.keyword} onChange={(e) => setFilter(prev => ({ ...prev, keyword: e.target.value }))} autoComplete="off" />
                                            </div>
                                        </div>
                                        <div className="mt-3 mt-lg-0 col-lg-3">
                                            <div className="form-group">
                                                <label htmlFor="experience ">{t("job_role")}:</label>
                                                <Select className="form-control" name="job_roles" id="experience" options={roles} value={filter.job_roles} onChange={(role) => setFilter(prev => ({ ...prev, job_roles: role }))} placeholder={t("job_role")} autoComplete="off" />
                                            </div>
                                        </div>
                                        <div className="mt-3 mt-lg-0 col-lg-3">
                                            <div className="form-group">
                                                <label htmlFor="location">{t("location")}:</label>
                                                <input type="text" className="form-control" id="location" placeholder={t("location")} value={filter.location} onChange={(e) => setFilter(prev => ({ ...prev, location: e.target.value }))} autoComplete="off" />
                                            </div>
                                        </div>
                                        <div className="mt-3 mt-lg-0 col-lg-3">
                                            <Link to="/jobs" onClick={() => localStorage.setItem('job_filter', JSON.stringify(filter))} className="find-btn" title={t("find_a_job")}>{t("find_a_job")} <i className="bx bx-search"></i></Link>
                                        </div>
                                    </div>
                                </form>
                                <ul className="keyword ">
                                    <li className="bold">{t("trending_cateories")}: &nbsp;</li>
                                    {data && data.map((category, key) => <li key={key}><Link to="/jobs" onClick={() => localStorage.setItem('job_filter', JSON.stringify({ job_categories: category.id }))}>{category.name}{data.length  !== (key+1) &&<>,&nbsp;</>}</Link></li>)}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BannerSection
import { useEffect, useState } from "react";
import DataTables, { reloadDataTable } from "../Helpers/Datatables";

const NotificationList = (props) => {

    const [dt] = useState({
        dt_url: props.listUrl,
        dt_name: 'job-alert-list',
        dt_column: [
            { data: "DT_RowIndex", name: 'id', title: "", sortable: false, searchable: false, className: "noExport" },
            { data: "data.title2", name: 'data.title', title: "Title", sortable: false, searchable: false },
            { data: "created_at", name: 'created_at', title: "Created At" },
        ],
    })

    useEffect(() => {
        reloadDataTable(dt, props.listUrl)
    }, [dt, props.listUrl])

    return (
        <DataTables dt={dt} dt_name="job-alert-list" />
    )
}

export default NotificationList